import React from 'react';
import { getDisplayLabelStyles } from '../utils';
import classNames from 'classnames';
import { useGetChevronComponent } from '../../CardFrameCollection/components/CarouselIndicator';

export function ListDescriptionSection({
  listPresentationConfig,
  isMobile,
  showArrow,
  sectionColors,
  carouselIndicatorConfigs,
  onNextArrowClick,
  onPrevArrowClick,
  containerHorizontalPadding,
  carouselConfigs,
  isLogoSliderCarousel = false,
  isFocusedReelExpandOnMobile = false,
}) {
  const displayLabelStyles = getDisplayLabelStyles(listPresentationConfig);

  const { LeftArrow, RightArrow } = useGetChevronComponent({
    type: carouselConfigs?.arrowShape,
    onNextArrowClick,
    onPrevArrowClick,
    isMobile,
    scrollPercentage: carouselIndicatorConfigs.scrollPercentage,
    colorConfig: {
      arrowColor: carouselConfigs?.arrowColor,
      shapeColor: carouselConfigs?.shapeColor,
    },
  });

  const isTextCenter = listPresentationConfig.textAlign === 'center' || true;

  return (
    <div
      className={classNames(
        'tw-relative tw-mx-auto tw-flex tw-flex-col',
        isMobile
          ? !isLogoSliderCarousel
            ? isFocusedReelExpandOnMobile
              ? 'tw-mb-[36px]'
              : 'tw-mb-[24px]'
            : ''
          : isLogoSliderCarousel
            ? 'tw-mb-[1px]'
            : 'tw-mb-[36px]',
        listPresentationConfig?.descriptionPosition === 'top' ? 'tw-flex-col-reverse' : ''
      )}
      style={{
        ...displayLabelStyles,
        padding: `0px ${containerHorizontalPadding}px`,
      }}
    >
      <div className="tw-flex tw-w-[100%] tw-flex-col" style={displayLabelStyles}>
        <div
          className={`tw-m-0 [&>*]:tw-m-0 [&>*]:tw-font-normal [&>*]:tw-text-inherit ${isTextCenter ? 'tw-text-center' : ''}`}
          style={{
            color: sectionColors?.text,
          }}
          dangerouslySetInnerHTML={{
            __html: listPresentationConfig?.heading || '<h2>Browse The Range</h2>',
          }}
        />
        {!isMobile && showArrow && carouselConfigs?.arrowPosition === 'top' && (
          <div className="tw-absolute tw-right-0 tw-top-[50%] tw-flex tw-translate-y-[calc(-50%_-_2px)] tw-gap-[8px]">
            <LeftArrow />
            <RightArrow />
          </div>
        )}
      </div>

      <div
        className="tw-m-0 tw-mt-[8px] tw-leading-[1.4] [&>*]:tw-m-0 [&>*]:tw-text-inherit"
        style={{
          color: '#808080',
          textAlign: listPresentationConfig?.textAlign === 'center' ? 'center' : 'left',
        }}
        dangerouslySetInnerHTML={{
          __html: listPresentationConfig?.description,
        }}
      />
    </div>
  );
}
