import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DEFAULT_IMAGE } from '../../Constants';
import useDisableScroll from '../../../../hooks/useDisableScroll';

// let intervalRef = null;
const AuthorImageList = ({
  listPresentationConfig,
  cardFrameConfig,
  carouselIndicatorConfigs,
  isMobile,
  carouselScrollTo,
  isBuilder,
}) => {
  // const intervalRef = useRef(null);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const authorImageRef = useRef<Record<number, HTMLDivElement>>({});
  const autoPlayConfig = listPresentationConfig?.autoPlayConfig;
  const activeTestimonialIdx = carouselIndicatorConfigs.currentFocusedSectionItem - 1;
  const authorImageSize = !isMobile ? 40 : 20;
  const gap = !isMobile ? 20 : 10;
  const maxWidth = (() => {
    const numToShow = 3;
    const maxWidth = numToShow * authorImageSize;
    return maxWidth + (numToShow - 1) * gap;
  })();
  const testimonialsData = cardFrameConfig?.testimonialsList || [];
  const isLayout4 = listPresentationConfig.layoutName === 'layout4';
  const isVisible = isLayout4 && !!testimonialsData?.length;

  useDisableScroll({
    disableScroll: autoPlayConfig?.enabled,
    scrollRef: scrollerRef.current,
  });

  useEffect(() => {
    if (scrollerRef.current) {
      if (isBuilder) {
        // const scrollerPosition =
        //   scrollerRef.current.getBoundingClientRect()?.left + scrollerRef.current.clientWidth/2;
        const authorImageRect =
          authorImageRef.current[activeTestimonialIdx]?.getBoundingClientRect();
        if (authorImageRect) {
          const toScrollWidth =
            authorImageRect.left - scrollerRef.current.getBoundingClientRect()?.left;

          scrollerRef.current.scrollTo({
            left: toScrollWidth,
          });
        }
      } else {
        authorImageRef.current[activeTestimonialIdx]?.scrollIntoView({
          inline: 'center',
        });
      }
    }
  }, [activeTestimonialIdx]);

  // useEffect(() => {
  //   if (autoPlayConfig?.enabled) {
  //     intervalRef = setInterval(() => {
  //       carouselScrollTo?.(
  //         activeTestimonialIdx + 1 === carouselIndicatorConfigs?.overflowSectionCount
  //           ? 0
  //           : activeTestimonialIdx + 1
  //       );
  //     }, autoPlayConfig?.autoplayTimer * 1000);
  //   }
  //   if (!autoPlayConfig?.enabled) {
  //     clearInterval(intervalRef);
  //     intervalRef = null;
  //   }
  //   return () => {
  //     clearInterval(intervalRef);
  //     intervalRef = null;
  //   };
  // }, [
  //   autoPlayConfig?.enabled,
  //   listPresentationConfig?.layoutName,
  //   activeTestimonialIdx,
  //   carouselIndicatorConfigs?.overflowSectionCount,
  // ]);

  if (!isVisible) {
    return null;
  }

  function getImageClasses(index) {
    const isActive = index === activeTestimonialIdx;
    return isActive
      ? 'tw-border-b tw-border-solid tw-border-[#000000]'
      : ' tw-opacity-[70]';
  }

  return (
    <div className="tw-mt-[20px] tw-flex tw-items-center">
      <div
        ref={scrollerRef}
        className={classNames(
          'no-scrollbar tw-flex tw-flex-shrink-0 tw-overflow-auto',
          isMobile ? 'tw-gap-[10px]' : 'tw-gap-[20px]'
        )}
        style={{
          maxWidth: `${maxWidth}px`,
        }}
      >
        {testimonialsData.map((testimonialData, index) => {
          return (
            <div
              ref={(node) => {
                authorImageRef.current[index] = node;
              }}
              onClick={() => {
                carouselScrollTo?.(index);
              }}
              key={index}
              className={classNames(
                getImageClasses(index),
                'tw-flex-shrink-0 tw-cursor-pointer tw-pb-[8px]'
              )}
              style={{
                width: `${authorImageSize}px`,
              }}
            >
              <div className="tw-flex tw-overflow-hidden tw-rounded-full">
                <img
                  src={testimonialData?.authorMedia?.link || DEFAULT_IMAGE}
                  className="tw-h-[44px] tw-w-[44px] !tw-rounded-full tw-object-contain"
                  style={{
                    width: `${authorImageSize}px`,
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AuthorImageList;
