import router from 'next/router';
import { deviceWidth } from 'src/utils/deviceWidth';
import { NoItemInCart, NoItemInCartSection, NoItemInCartText } from '../../cart.styles';
import { ZeroCart } from 'src/assets/svgExports/ZeroCart';
import getCustomCtaText from 'src/utils/getCustomCtaText';
import { getRoute, PATH } from 'src/utils/routes';
import { useSSRSelector } from 'src/redux';
import { isPLPRequiredSystem } from 'src/utils/isPLPRequiredSystem';
import { getThemeColor } from 'src/utils/getThemeColor';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import { Button } from 'src/components/DesignSystem/AtomicComponents';
import { canDisablePlaceOrder } from 'src/utils/canDisablePlaceOrder';
import { toastNotifyError } from 'src/components/Common/Toast';

const EmptyCartView = () => {
  const themeColor = getThemeColor();

  const { storeInfo, collections } = useSSRSelector((state) => ({
    storeInfo: state.storeReducer.store,
    collections: state.catalogReducer.collections,
  }));

  const shopButtonClick = () => {
    if (canDisablePlaceOrder()) {
      toastNotifyError(
        'This is a demo website, you need a live version to proceed this action.'
      );
      return;
    }
    if (collections?.length) {
      router.push(getRoute(PATH.PRODUCT, storeInfo?.store_info?.domain));
    } else {
      if (isPLPRequiredSystem(storeInfo?.theme?.theme_class)) {
        if (storeInfo?.theme?.custom_theme_flag)
          router.push(getRoute(PATH.PRODUCT, storeInfo?.store_info?.domain));
        else router.push(getRoute(PATH.SHOP, storeInfo?.store_info?.domain));
      } else {
        router.push(getRoute(PATH.PRODUCT, storeInfo?.store_info?.domain));
      }
    }
  };

  return (
    <NoItemInCart>
      <NoItemInCartSection>
        <ZeroCart
          height={deviceWidth >= 767 ? 184.32 : 123}
          width={deviceWidth >= 767 ? 226.77 : 151}
        />
        <NoItemInCartText>
          Your cart is empty, Go ahead and add something to the cart
        </NoItemInCartText>
      </NoItemInCartSection>
      <Button
        px={IS_DESKTOP ? '75px' : '26px'}
        py="18px"
        fontColor="#ffffff"
        roundness={storeInfo?.theme?.roundness}
        backgroundColor={themeColor}
        label={getCustomCtaText('cart_cta')}
        variant="solid"
        fontSize={deviceWidth >= 767 ? 20 : 18}
        onClick={shopButtonClick}
      />
    </NoItemInCart>
  );
};

export default EmptyCartView;
