import { put, takeEvery } from 'redux-saga/effects';
import { toastNotifyError, toastNotifySuccess } from '@/components/Common/Toast';
import apiCall from './../../services/api';
import {
  GET_WIDGET_THEME_INFO,
  ADD_WIDGET,
  UPDATE_WIDGET,
  SUBMIT_FORM_DATA,
  SAVE_ACTIVE_STYLE_TEMPLATE,
  ADD_MEDIA_TO_STORE,
  UPDATE_MEDIA_TO_STORE,
  DELETE_MEDIA_FROM_STORE,
  UPLOAD_PDF_TO_S3,
  CREATE_THEME,
  UPDATE_THEME,
  PUBLISH_THEME,
  SET_STORE_WEBSITE_STATUS,
  GET_DRAFT_THEME_COMPONENTS,
} from './../actionTypes';
import { ENDPOINT } from 'src/config/endpoints';
import {
  fetchStoreInfoBySubDomain,
  saveWidgetThemeInfo,
  setDraftThemeComponents,
} from '../actions';
import store from '../store';

function* getWidgetThemeInfo(actions) {
  try {
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.WIDGETS.getWidgetThemeContent,
      parseToJson: true,
    });
    if (response && response.data.status) {
      yield put(saveWidgetThemeInfo(response?.data?.data));
      actions.callback && actions.callback(response.data.data);
    } else {
      actions.callback && actions.callback(false);
    }
  } catch (err) {
    toastNotifyError(err.data.message);
  }
}

function* addWidget(actions) {
  const storeData = store.getState().storeReducer.store;
  try {
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.WIDGETS.addWidget,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(fetchStoreInfoBySubDomain(storeData?.store_info?.domain));
      const data = JSON.parse(response.data.data);
      actions.callback && actions.callback(true, data);
    }
  } catch (err) {
    actions.callback && actions.callback(false, null);
    console.log(err);
  }
}

function* updateWidget(actions) {
  const storeData = store.getState().storeReducer.store;
  try {
    const response = yield apiCall({
      method: 'PUT',
      url: ENDPOINT.WIDGETS.updateWidget,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(fetchStoreInfoBySubDomain(storeData?.store_info?.domain));
      const data = JSON.parse(response.data.data);
      actions.callback && actions.callback(true, data);
    }
  } catch (err) {
    actions.callback && actions.callback(false, null);
    console.log(err);
  }
}

function* submitFormData(actions) {
  try {
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.WIDGETS.submitForm,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback && actions.callback(true);
    }
  } catch (err) {
    actions.callback && actions.callback(false, err?.data?.message);
    console.log(err);
  }
}

function* saveStoreStyleTemplate(actions) {
  try {
    const response = yield apiCall({
      method: 'PATCH',
      url: ENDPOINT.WIDGETS.saveStyleTemplate,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback && actions.callback(true);
    }
  } catch (err) {
    actions.callback && actions.callback(false, null);
    console.log(err);
  }
}

function* addMediaToStore(actions) {
  try {
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.WIDGETS.addMediaToStore,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback && actions.callback(true);
    }
  } catch (err) {
    actions.callback && actions.callback(false, err?.data?.message);
    console.log(err);
  }
}

function* updateMediaToStore(actions) {
  const storeData = store.getState().storeReducer.store;
  try {
    const response = yield apiCall({
      method: 'PUT',
      url: ENDPOINT.WIDGETS.updateMediaToStore,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(fetchStoreInfoBySubDomain(storeData?.store_info?.domain));
      const data = JSON.parse(response.data.data);
      actions.callback && actions.callback(true, data);
    }
  } catch (err) {
    actions.callback && actions.callback(false, null);
    console.log(err);
  }
}

function* deleteMediaFromStore(actions) {
  try {
    const response = yield apiCall({
      method: 'DELETE',
      url: ENDPOINT.WIDGETS.deleteMediaFromStore,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions && actions.callback(response);
    } else {
    }
  } catch (err) {
    history.goBack();
  }
}

function* uploadPdfToS3(actions) {
  try {
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.WIDGETS.uploadMediaToS3,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = JSON.parse(response?.data?.data);
      actions?.callback && actions?.callback(parsedData);
    }
  } catch (err) {
    toastNotifyError(err?.data?.message);
    actions.callback && actions.callback(false, err?.data?.message);
    console.log(err);
  }
}

function* createTheme(actions) {
  try {
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.WIDGETS.addEditTheme,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = JSON.parse(response?.data?.data);
      actions?.callback && actions?.callback(true, parsedData);
    }
  } catch (err) {
    toastNotifyError(err?.data?.message);
    actions.callback && actions.callback(false, err?.data?.message);
    console.log(err);
  }
}

function* updateTheme(actions) {
  try {
    const response = yield apiCall({
      method: 'PUT',
      url: ENDPOINT.WIDGETS.addEditTheme,
      data: actions.data,
    });
    if (response && response.data.status) {
      const parsedData = JSON.parse(response?.data?.data);
      actions?.callback && actions?.callback(true, parsedData);
    }
  } catch (err) {
    toastNotifyError(err?.data?.message);
    actions.callback && actions.callback(false, err?.data?.message);
    console.log(err);
  }
}

function* publishTheme(actions) {
  try {
    const response = yield apiCall({
      method: 'PATCH',
      url: ENDPOINT.WIDGETS.publishTheme,
      data: actions.data,
    });
    if (response && response.data.status) {
      toastNotifySuccess('Theme Published successfully');
    }
  } catch (err) {
    toastNotifyError(err?.data?.message);
    actions.callback && actions.callback(false, err?.data?.message);
    console.log(err);
  }
}

function* setStoreWebsiteStatus(actions) {
  try {
    const response = yield apiCall({
      method: 'PATCH',
      url: ENDPOINT.WIDGETS.setStoreWebsiteStatus,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback?.(true);
    } else {
      actions.callback?.(false);
    }
  } catch (err) {
    toastNotifyError(err?.data?.message);
    actions.callback && actions.callback(false, err?.data?.message);
    console.log(err);
  }
}

function* getDraftThemeComponents(actions) {
  try {
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.ADDITIONAL_PAGES.getDraftWidgets(actions.data),
      data: actions.data,
      parseToJson: true,
    });
    if (response && response.data.status) {
      yield put(setDraftThemeComponents(response.data.data));
      actions.callback?.(true);
    } else {
      actions.callback?.(false);
    }
  } catch (err) {
    toastNotifyError(err?.data?.message);
    actions.callback && actions.callback(false, err?.data?.message);
    console.log(err);
  }
}

export default function* root() {
  yield takeEvery(GET_WIDGET_THEME_INFO, getWidgetThemeInfo);
  yield takeEvery(ADD_WIDGET, addWidget);
  yield takeEvery(UPDATE_WIDGET, updateWidget);
  yield takeEvery(SUBMIT_FORM_DATA, submitFormData);
  yield takeEvery(SAVE_ACTIVE_STYLE_TEMPLATE, saveStoreStyleTemplate);
  yield takeEvery(ADD_MEDIA_TO_STORE, addMediaToStore);
  yield takeEvery(UPDATE_MEDIA_TO_STORE, updateMediaToStore);
  yield takeEvery(DELETE_MEDIA_FROM_STORE, deleteMediaFromStore);
  yield takeEvery(UPLOAD_PDF_TO_S3, uploadPdfToS3);
  yield takeEvery(CREATE_THEME, createTheme);
  yield takeEvery(UPDATE_THEME, updateTheme);
  yield takeEvery(PUBLISH_THEME, publishTheme);
  yield takeEvery(SET_STORE_WEBSITE_STATUS, setStoreWebsiteStatus);
  yield takeEvery(GET_DRAFT_THEME_COMPONENTS, getDraftThemeComponents);
}
