import { put, takeEvery } from 'redux-saga/effects';
import { ENDPOINT } from './../../config/endpoints';
import apiCall from './../../services/api';
import { saveFeatureLocks, saveStoreInfo, saveB2bRfqInputFields } from './../actions';
import {
  FETCH_FEATURE_LOCKS,
  FETCH_STORE_BY_SUBDOMAIN,
  GET_B2B_RFQ_INPUT_FIELDS,
} from '../actionTypes';
import store from './../store';
import { PATH, getRoute } from './../../utils/routes';
import { toastNotifyError } from '../../components/Common/Toast';
import { transformInputAccordingToPriorityAssignedByB2bMerchant } from '@/containers/ProductDescription/Components/B2b/utils/util';

function* fetchStoreInfoBySubDomain(actions) {
  try {
    const storeResponse = yield apiCall({
      method: 'GET',
      url:
        !window.location.pathname?.includes(PATH.BUILDER_PREVIEW) &&
        !window.location.pathname?.includes(PATH.WEBSITE_BUILDER)
          ? ENDPOINT.STORE.getStoreBySubDomain(actions.subdomain)
          : ENDPOINT.STORE.getStoreBySubDomainWithToken(actions.subdomain),
    });
    if (storeResponse && storeResponse.data.status) {
      yield put(saveStoreInfo(storeResponse.data.store));
      /* 
            Removing store closed component from web app 
            Now store closed will remove some components from user perspective
            No redirection will be necessary
          */
      // if (!storeResponse.data.store.services.store_flag) {
      //   actions.router.push(getRoute(PATH.STORE_CLOSE, storeResponse.data.store.store_info.domain));
      // }
    } else {
      toastNotifyError(storeResponse.data.message);
      actions.router && actions.router.push(getRoute(PATH._404));
    }
  } catch (err) {
    toastNotifyError(err.data.message);
    actions.router && actions.router.push(getRoute(PATH._404));
  }
}

function* fetchFeatureLocks(actions) {
  try {
    const response = yield apiCall({
      url: ENDPOINT.STORE.getFeatureLocks(actions.storeId),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveFeatureLocks(response.data.data));
    }
  } catch (err) {
    //
  }
}

function* fetchB2bRfqInputFields(actions) {
  const storeId = actions?.storeId ?? store.getState().storeReducer.store.store_id;
  try {
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.STORE.getB2bRfqInputFields(storeId),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      const transformedInputFields =
        transformInputAccordingToPriorityAssignedByB2bMerchant(response?.data?.data);
      yield put(saveB2bRfqInputFields(transformedInputFields));
      actions?.callback?.();
    }
  } catch (err) {
    toastNotifyError(err?.data?.message);
  }
}

export default function* root() {
  yield takeEvery(FETCH_STORE_BY_SUBDOMAIN, fetchStoreInfoBySubDomain);
  yield takeEvery(FETCH_FEATURE_LOCKS, fetchFeatureLocks);
  yield takeEvery(GET_B2B_RFQ_INPUT_FIELDS, fetchB2bRfqInputFields);
}
