import store from '../redux/store';
import { deviceWidth } from '@/utils/deviceWidth';
import { StoreDetails } from 'src/redux/reducers';
import { StoreTheme } from 'src/redux/reducers/store';

export const specificThemeRender = (
  storeData: StoreDetails,
  isSubscriptionDemoPreview = false
) => {
  const mainTheme = storeData?.theme || ({} as StoreTheme);
  const alternateTheme = storeData?.store_premium_theme || ({} as any);
  const isPremium = storeData?.premium;

  if (isSubscriptionDemoPreview || storeData.internal_admin_access === 1)
    return mainTheme;

  const toggleMobileView = store.getState().commonReducer.toggleMobileView;
  if (deviceWidth > 998) {
    return isPremium || mainTheme?.is_demo === 1 || mainTheme?.brand_website_flag > 0
      ? mainTheme
      : toggleMobileView
        ? mainTheme
        : alternateTheme;
  }
  return mainTheme;
};
