import { ADMIN_WIDGETS } from '@/constants/widgets';
import {
  ASPECT_RATIO_MAP,
  TEXT_CASE,
  TEXT_SIZE_DROPDOWN,
  TEXT_THICKNESS,
} from '../ListPresentation/ListPresentationConfig';
import { getDefaultConfig } from '@/components/WidgetMaker/utils/common';
import {
  DESCRIPTION_POSITION,
  HEADING_ALIGNMENT,
} from '../MultiColumn/MultiColumnStaticValues';
import { PRODUCT_APPEARANCE_DATA } from '../../GlobalStyle/globalSettingConstant';
import { getCardShare } from '../MultiColumn/MultiColumnConfig';
import { LAYOUT_STYLE } from '../LogoSlider/LogoSliderConfig';

export const TESTIMONIALS_PRESENTATION_CONFIG = {
  layoutName: 'layout1',
  viewLabel: 'Testimonials',
  widgetName: ADMIN_WIDGETS.TESTIMONIALS.type,
  layoutType: 'carousel',
  heading: '<h1>What customers say about us?</h1>',
  descriptionPosition: 'below',
  textAlign: 'center',
  headingCharCount: 0,
  instagramAccount: '',
  description: '',
  descriptionCharCount: 0,
  type: 'testimonials',
  colors: {
    selectedColorSchemeIndex: 0,
    colorSchemes: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: true,
      },
      {
        background: '#000000',
        text: '#ffffff',
        isDefault: false,
      },
    ],
  },
  contentItems: {
    isAllSelected: false,
    type: 'testimonials',
    listItem: [],
  },
  appearance: 'standard',
  imageWidth: 'fullWidth',
  backgroundColor: '#FFFFFF',
  borderColor: '#E2E4E7',
  numColumnsDesktop: 3,
  numColumnsMobile: 1,
  mobilePaddingY: 40,
  paddingY: 40,
  marginTop: 0,
  marginBottom: 0,
  autoPlayConfig: {
    enabled: false,
    autoplayTimer: 5,
    autoRotateEnabled: false,
    autoRotateTimer: 5,
  },
};

export const TESTIMONIALS_CARD_CONFIG = {
  cardShape: 'portraitTall',
  aspectRatio: ASPECT_RATIO_MAP.portraitTall,
  hoverEffect: 'zoomIn',
  textAlign: 'center',
  textSizeDesktop: 'medium',
  textSizeMobile: 'small',
  headlineSizeDesktop: 'medium',
  headlineSizeMobile: 'small',
  headlineColor: '#111C36',
  reviewColor: '#111C36E6',
  authorNameColor: '#111C36',
  designationColor: '#111C36B3',
  ratingColor: '#F9CC43',
  textThickness: 'regular',
  headlineThickness: 'semiBold',
  textCase: 'none',
  headlineCase: 'none',
  hoverType: 'zoomIn',
  showRatings: true,
  showImage: true,
  imageSizeDesktop: 40,
  imageSizeMobile: 40,
  authorImagePosition: 'withAuthorInfo',
  imageShape: 'circle',
  testimonialsList: [],
  childElementOrder: [
    {
      name: 'Rating',
      id: 'rating',
      isSortable: true,
    },
    {
      name: 'Review',
      id: 'review',
      isSortable: true,
    },
    {
      name: 'Author info',
      id: 'authorInfo',
      isSortable: true,
    },
  ],
  buttonConfig: [
    {
      ...getDefaultConfig('bannerButton', '#111C36', '#FFFFFF', 'Shop now'),
    },
  ],
};

export const CARD_SHAPE_DROPDOWN = [
  {
    value: 'square',
    renderData: () => getCardShare('Square'),
  },
  {
    value: 'circle',
    renderData: () => getCardShare('Circle'),
  },
  {
    value: 'smart',
    renderData: () => getCardShare('Logo friendly (Smart fit in Rectangle or square)'),
  },
];

export const AUTHOR_IMAGE_POSITION_DROPDOWN = [
  {
    value: 'withAuthorInfo',
    renderData: () => getCardShare('Show with author info'),
  },
  {
    value: 'top',
    renderData: () => getCardShare('Show at top'),
  },
  {
    value: 'bottom',
    renderData: () => getCardShare('Show at bottom'),
  },
];

export const TESTIMONIAL_ITEM_DEFAULT_DATA = {
  review: '',
  authorName: '',
  authorDesignation: '',
  headline: '',
  authorMedia: {
    link: '',
    type: '',
  },
};

export const TESTIMONIALS_BUILDER_CONFIG = {
  sectionContent: {
    sectionHeading: 'Section content',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Heading',
        element: 'inputEditor',
        accessKey: ['heading'],
        charCountKey: 'headingCharCount',
        placeholder: 'Enter title',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Description',
        element: 'inputEditor',
        placeholder: 'Enter description',
        charCountKey: 'descriptionCharCount',
        accessKey: ['description'],
        textColor: '#808080',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Description position',
        element: 'multiselect',
        accessKey: ['descriptionPosition'],
        componentData: DESCRIPTION_POSITION,
      },
      {
        forType: 'layout',
        componentLabel: 'Heading alignment',
        element: 'multiselect',
        accessKey: ['textAlign'],
        componentData: HEADING_ALIGNMENT,
      },
      {
        forType: 'layout',
        componentLabel: 'Section colour',
        editLabel: 'Edit colour',
        uniqueKey: 'selectionColors',
        element: 'colorsCombination',
        accessKey: ['colors'],
      },
    ],
  },
  imageAndRatings: {
    sectionHeading: 'Image and ratings',
    description: '',
    tooltipContent: '',
    builder: [
      {
        element: 'renderWithHeading',
        heading: '',
        child: [
          {
            forType: 'card',
            element: 'checkbox',
            componentLabel: 'Show ratings',
            accessKey: ['showRatings'],
          },
          {
            forType: 'card',
            element: 'checkbox',
            componentLabel: 'Show image',
            accessKey: ['showImage'],
          },
        ],
      },
      {
        forType: 'card',
        componentLabel: 'Image size - Desktop',
        element: 'slider',
        accessKey: ['imageSizeDesktop'],
        min: 20,
        max: 100,
        dynamicData: true,
        getDynamicData: (layoutPresentation) => {
          if (
            layoutPresentation.layoutName === 'layout5' ||
            layoutPresentation.layoutName === 'layout6'
          ) {
            return { min: 20, max: 100 };
          }
          return {
            min: 40,
            max: 80,
          };
        },
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'card',
        componentLabel: 'Image size - Mobile',
        element: 'slider',
        accessKey: ['imageSizeMobile'],
        min: 20,
        max: 100,
        dynamicData: true,
        getDynamicData: (layoutPresentation) => {
          if (
            layoutPresentation.layoutName === 'layout5' ||
            layoutPresentation.layoutName === 'layout6'
          ) {
            return { min: 20, max: 100 };
          }
          return {
            min: 40,
            max: 80,
          };
        },
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Author image position',
        accessKey: ['authorImagePosition'],
        dropdownData: AUTHOR_IMAGE_POSITION_DROPDOWN,
        customChildRender: true,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Image shape',
        accessKey: ['imageShape'],
        dropdownData: CARD_SHAPE_DROPDOWN,
        customChildRender: true,
        placeholder: '',
      },
    ],
  },
  layoutAndDesign: {
    sectionHeading: 'Layout & design',
    description: '',
    tooltipContent: '',
    builder: [
      {
        componentLabel: 'Layout/design',
        element: 'testimonialLayout',
      },
      {
        forType: 'layout',
        element: 'multiselect',
        componentLabel: 'Layout style',
        accessKey: ['layoutType'],
        dynamicData: false,
        componentData: LAYOUT_STYLE,
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Desktop',
        element: 'slider',
        accessKey: ['numColumnsDesktop'],
        min: 1,
        max: 2,
        dynamicData: true,
        getDynamicData: (layoutPresentation) => {
          if (layoutPresentation.layoutName === 'layout4') {
            return { min: 1, max: 1 };
          }
          return {
            min: 1,
            max: 2,
          };
        },
        suffix: '',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Mobile',
        element: 'slider',
        accessKey: ['numColumnsMobile'],
        min: 1,
        max: 2,
        dynamicData: true,
        getDynamicData: (layoutPresentation) => {
          if (layoutPresentation.layoutName === 'layout4') {
            return { min: 1, max: 1 };
          }
          return {
            min: 1,
            max: 2,
          };
        },
        suffix: '',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Product appearance',
        element: 'multiselect',
        accessKey: ['appearance'],
        componentData: PRODUCT_APPEARANCE_DATA,
        renderConditional: true,
        isVisible: (data, list) => list?.layoutName !== 'layout2',
      },
      // {
      //   forType: 'layout',
      //   componentLabel: 'Reel width',
      //   element: 'multiselect',
      //   renderConditional: true,
      //   isVisible: (data, list) =>
      //     list?.appearance === 'card' && list?.layoutName !== 'layout2',
      //   accessKey: ['imageWidth'],
      //   componentData: IMAGE_WIDTH_DATA,
      // },
      {
        element: 'colorDetails',
        componentLabel: 'Colour',
        isVisible: (data) => data?.appearance === 'card',
        conditionalRender: true,
        forType: 'layout',
        colorsData: [
          {
            forType: 'layout',
            text: 'backgroundColor',
            isVisible: (data) => data?.appearance === 'card',
            renderConditional: true,
            displayVal: 'Background colour',
            accessKey: ['backgroundColor'],
            shouldUpdateData: false,
            data: [],
          },
          {
            forType: 'layout',
            text: 'borderColor',
            isVisible: (data) => data?.appearance === 'card',
            renderConditional: true,
            displayVal: 'Border colour',
            accessKey: ['borderColor'],
            shouldUpdateData: false,
            data: [],
          },
        ],
      },
    ],
  },
  testimonialsContent: {
    sectionHeading: 'Testimonials content',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'card',
        componentLabel: 'Content alignment',
        element: 'multiselect',
        accessKey: ['textAlign'],
        componentData: HEADING_ALIGNMENT,
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Testimonial text size - Desktop',
        accessKey: ['textSizeDesktop'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Testimonial text size - Mobile',
        accessKey: ['textSizeMobile'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Headline price size - Desktop',
        accessKey: ['headlineSizeDesktop'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Headline price size - Mobile',
        accessKey: ['headlineSizeMobile'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        componentLabel: 'Advanced settings',
        componentSubLabel: 'Customise Text casing, content thickness, colour.',
        element: 'customModal',
        modalHeading: 'Advance settings',
        componentData: [
          {
            element: 'colorDetails',
            componentLabel: 'Colours',
            forType: 'card',
            colorsData: [
              {
                forType: 'card',
                text: 'headlineColor',
                displayVal: 'Headline color',
                accessKey: ['headlineColor'],
                shouldUpdateData: false,
                data: [],
              },
              {
                forType: 'card',
                text: 'reviewColor',
                displayVal: 'Review color',
                accessKey: ['reviewColor'],
                shouldUpdateData: false,
                data: [],
              },
              {
                forType: 'card',
                text: 'authorNameColor',
                displayVal: 'Author name color',
                accessKey: ['authorNameColor'],
                shouldUpdateData: false,
                data: [],
              },
              {
                forType: 'card',
                text: 'designationColor',
                displayVal: 'Designation color',
                accessKey: ['designationColor'],
                shouldUpdateData: false,
                data: [],
              },
              {
                forType: 'card',
                text: 'ratingColor',
                displayVal: 'Rating color',
                accessKey: ['ratingColor'],
                shouldUpdateData: false,
                data: [],
              },
            ],
          },
          {
            forType: 'card',
            componentLabel: 'Headline text case',
            element: 'multiselect',
            accessKey: ['headlineCase'],
            componentData: TEXT_CASE,
          },
          {
            forType: 'card',
            componentLabel: 'Review text case',
            element: 'multiselect',
            accessKey: ['textCase'],
            componentData: TEXT_CASE,
          },
          {
            forType: 'card',
            element: 'dropdown',
            componentLabel: 'Headline thickness',
            accessKey: ['headlineThickness'],
            dropdownData: TEXT_THICKNESS,
            customChildRender: false,
            placeholder: '',
          },
          {
            forType: 'card',
            element: 'dropdown',
            componentLabel: 'Review thickness',
            accessKey: ['textThickness'],
            dropdownData: TEXT_THICKNESS,
            customChildRender: false,
            placeholder: '',
          },
        ],
      },
    ],
  },
  carouselBehaviour: {
    sectionHeading: 'Carousel behaviour',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'layout',
        componentLabel: '',
        element: 'radioButton',
        accessKey: ['autoPlayConfig', 'enabled'],
        conditionalStyle: false,
        componentData: [
          {
            label: 'Manual scroll (default)',
            id: 'autoOff',
            value: false,
          },
        ],
      },
      {
        forType: 'layout',
        componentLabel: '',
        element: 'radioButton',
        accessKey: ['autoPlayConfig', 'enabled'],
        conditionalStyle: false,
        componentData: [
          {
            label: 'Auto-scroll',
            id: 'autoOn',
            value: true,
            child: [
              {
                forType: 'layout',
                element: 'autoSlide',
                componentLabel: 'Change testimonial every:',
                accessKey: ['autoPlayConfig', 'autoplayTimer'],
                min: 4,
                max: 6,
                renderConditional: true,
                isVisible: (listPresentationConfig) => {
                  return listPresentationConfig?.autoPlayConfig?.enabled;
                },
                placeholder: '',
              },
            ],
          },
        ],
      },
      {
        forType: 'layout',
        componentLabel: '',
        element: 'radioButton',
        accessKey: ['autoPlayConfig', 'autoRotateEnabled'],
        conditionalStyle: false,
        componentData: [
          {
            label: 'Auto-rotate testimonials',
            id: 'autoOn',
            value: true,
            child: [
              {
                forType: 'layout',
                element: 'autoSlide',
                componentLabel: 'Change testimonial every:',
                accessKey: ['autoPlayConfig', 'autoRotateTimer'],
                min: 4,
                max: 6,
                renderConditional: true,
                isVisible: (listPresentationConfig) => {
                  return listPresentationConfig?.autoPlayConfig?.enabled;
                },
                placeholder: '',
              },
            ],
          },
        ],
      },
      // {
      //   forType: 'layout',
      //   element: 'autoSlide',
      //   componentLabel: 'Change testimonial every:',
      //   accessKey: ['autoPlayConfig', 'autoplayTimer'],
      //   renderConditional: true,
      //   isVisible: (listPresentationConfig) => {
      //     return listPresentationConfig?.autoPlayConfig?.enabled;
      //   },
      //   placeholder: '',
      // },

      // {
      //   forType: 'card',
      //   element: 'radioButton',
      //   componentLabel: 'Desktop behaviour',
      //   accessKey: ['reelDesktopBehaviour'],
      //   componentData: [
      //     {
      //       label: 'On',
      //       id: 'autoPlayAll',
      //       value: 'autoPlayAll',
      //     },
      //     {
      //       label: 'Off',
      //       id: 'playOnHover',
      //       value: 'playOnHover',
      //     },
      //     // {
      //     //   label: 'Autoplay one reel at a time',
      //     //   id: 'autoplayOne',
      //     //   value: 'autoplayOne',
      //     // },
      //   ],
      //   // extraContent: 'For mobile autoplay is best optimised for user experience.',
      //   placeholder: '',
      // },
      // {
      //   element: 'renderWithHeading',
      //   heading: 'Mobile behaviour',
      //   child: [
      //     {
      //       forType: 'card',
      //       element: 'checkbox',
      //       componentLabel: 'Enlarge and focus the playing reel',
      //       accessKey: ['mobileEnlargeAndFocus'],
      //     },
      //   ],
      // },
    ],
  },
  reelBehaviour: {
    sectionHeading: 'Autoplay',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'layout',
        componentLabel: '',
        element: 'radioButton',
        accessKey: ['autoPlayConfig', 'enabled'],
        conditionalStyle: false,
        componentData: [
          {
            label: 'On',
            id: 'autoOn',
            value: true,
            child: [
              {
                forType: 'layout',
                element: 'autoSlide',
                componentLabel: 'Change testimonial every:',
                accessKey: ['autoPlayConfig', 'autoplayTimer'],
                min: 4,
                max: 6,
                renderConditional: true,
                isVisible: (listPresentationConfig) => {
                  return listPresentationConfig?.autoPlayConfig?.enabled;
                },
                placeholder: '',
              },
            ],
          },
        ],
      },
      {
        forType: 'layout',
        componentLabel: '',
        element: 'radioButton',
        accessKey: ['autoPlayConfig', 'enabled'],
        conditionalStyle: false,
        componentData: [
          {
            label: 'Off',
            id: 'autoOff',
            value: false,
          },
        ],
      },
      // {
      //   forType: 'card',
      //   element: 'radioButton',
      //   componentLabel: 'Desktop behaviour',
      //   accessKey: ['reelDesktopBehaviour'],
      //   componentData: [
      //     {
      //       label: 'On',
      //       id: 'autoPlayAll',
      //       value: 'autoPlayAll',
      //     },
      //     {
      //       label: 'Off',
      //       id: 'playOnHover',
      //       value: 'playOnHover',
      //     },
      //     // {
      //     //   label: 'Autoplay one reel at a time',
      //     //   id: 'autoplayOne',
      //     //   value: 'autoplayOne',
      //     // },
      //   ],
      //   // extraContent: 'For mobile autoplay is best optimised for user experience.',
      //   placeholder: '',
      // },
      // {
      //   element: 'renderWithHeading',
      //   heading: 'Mobile behaviour',
      //   child: [
      //     {
      //       forType: 'card',
      //       element: 'checkbox',
      //       componentLabel: 'Enlarge and focus the playing reel',
      //       accessKey: ['mobileEnlargeAndFocus'],
      //     },
      //   ],
      // },
    ],
  },
  contentOrderSection: {
    sectionHeading: 'Content order',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'card',
        componentLabel: '',
        element: 'reArrange',
        accessKey: ['childElementOrder'],
        renderConditional: false,
        // componentData: HEADING_ALIGNMENT,
        sortableList: [
          {
            name: 'Rating',
            id: 'rating',
            isSortable: true,
          },
          {
            name: 'Review',
            id: 'review',
            isSortable: true,
          },
          {
            name: 'Author info',
            id: 'authorInfo',
            isSortable: true,
          },
        ],
      },
    ],
  },
  viewAllButton: {
    sectionHeading: 'Button inside testimonials',
    builder: [
      {
        forType: 'card',
        element: 'button',
        buttonHeading: 'Button',
        subHeading: '',
        showColorCombination: true,
        showDeleteIcon: false,
        showRedirectionField: false,
        keepSameColor: true,
        showOnOffToggle: false,
        accessKey: ['buttonConfig'],
      },
    ],
  },
  sectionSetting: {
    sectionHeading: 'Section settings',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Desktop padding top and bottom',
        element: 'slider',
        accessKey: ['paddingY'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Mobile padding top and bottom',
        element: 'slider',
        accessKey: ['mobilePaddingY'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin top',
        element: 'slider',
        accessKey: ['marginTop'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin bottom',
        element: 'slider',
        accessKey: ['marginBottom'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
    ],
  },
  configReset: {
    builder: [
      {
        forType: 'layout',
        element: 'reset',
        heading: 'Reset to defaults',
        subHeading:
          'All settings of this section - layout, design etc. will be reset to default.',
      },
    ],
  },
};

export const TESTIMONIALS_LAYOUT_SELECTABLES = [
  {
    name: 'layout1',
    heading: 'Showing reels only',
    cdn: '/assets/images/widgetImages/FeaturedReels/1.png',
    // These are the base configs meapped with each layout
    list: {
      layoutName: 'layout1',
      appearance: 'standard',
    },
    card: {},
  },
  {
    name: 'layout2',
    heading: 'Reels with Item details ',
    cdn: '/assets/images/widgetImages/FeaturedReels/2.png',
    // These are the base configs mapped with each layout
    list: {
      layoutName: 'layout2',
      appearance: 'card',
    },
    card: {},
  },
  {
    name: 'layout3',
    heading: 'Reels with Item details ',
    cdn: '/assets/images/widgetImages/FeaturedReels/3.png',
    // These are the base configs mapped with each layout
    list: {
      layoutName: 'layout3',
      appearance: 'standard',
    },
    card: {},
  },
  {
    name: 'layout4',
    heading: 'Reels with brief text',
    cdn: '/assets/images/widgetImages/FeaturedReels/4.png',
    // These are the base configs mapped with each layout
    list: {
      layoutName: 'layout4',
      appearance: 'standard',
    },
    card: {},
  },
  {
    name: 'layout5',
    heading: 'Reels with brief text',
    cdn: '/assets/images/widgetImages/FeaturedReels/4.png',
    // These are the base configs mapped with each layout
    list: {
      layoutName: 'layout5',
      appearance: 'standard',
    },
    card: {},
  },
  {
    name: 'layout6',
    heading: 'Reels with brief text',
    cdn: '/assets/images/widgetImages/FeaturedReels/4.png',
    // These are the base configs mapped with each layout
    list: {
      layoutName: 'layout6',
      appearance: 'card',
    },
    card: {},
  },
];
