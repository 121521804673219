/**
 * Desktop Payment Modal
 */
import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { batch, useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Modal from '../Common/Modal';
import QRPaymentUPI from '../QRPaymentUPI';
import CardPaymentBox from '../CardPaymentBox';
import EmiCardPayment from '../EmiCardPayment';
import CustomRadio from '../CustomRadio';
import LazyPayAuth from '../LazyPayAuth/lazyPayAuth';
import { getDeviceInfo } from '../../utils/deviceInfo';
import { checkForInvalidAmount } from '@/utils/checkForInvalidAmount';
import {
  fetchPaymentMethods,
  initLazyPayPayment,
  pushEventsLog,
  savePaymentInfo,
  setPaymentModalVisiblity,
  updateOrderState,
  initPayment,
  setCustomerTransactionPaymentMethod,
  prePaymentCheckMDRTransactions,
  togglePartialPaymentMode,
  fetchOrderByHash,
  fetchOrderDetailByID,
  setPaymentModalOptionsVisiblity,
  authenticateUser,
} from 'src/redux/actions';
import { roundnessCalculator } from '../DesignSystem/AtomicComponents/util';
import {
  ACTIVE_PAYMENT_METHOD,
  MAX_MOBILE_WIDTH,
  ORDER_TYPE,
  PAYMENT_TYPE_CONSTANTS,
  PREPAID_POSTPAID_METHODS,
  PARTIAL_PAYMENT_OPTION,
  MINIMUM_ORDER_AMOUNT,
  COD_CHARGE_TYPE,
} from '@/utils/constants';
import { deviceWidth } from '@/utils/deviceWidth';
import { getPrepaidInvalidAmountMessage } from '@/utils/getPrepaidInvalidAmountMessage';
import { isOrderGstExcluded, isOrderGstIncluded } from '@/utils/orderGSTStatus';
import { getStoreType } from '@/utils/getStoreType';
import { getEncrypDecryptStr } from '@/utils/encryptionDecryption';
import { checkIfObjectExists } from '@/utils/checkIfObjectExists';
import { toastNotifyError } from '../Common/Toast';
import getCustomCtaText from '@/utils/getCustomCtaText';
import MobikwikPayment from '../MobikwikPayment';
// import LoaderIcon from '@/components/Common/LoaderIcon';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import AuthModal from '@/components/DesignSystem/AuthPopUp';
import { getRoute, PATH } from '@/utils/routes';
import { IS_SERVER } from '@/utils/checkRenderEnv';
import { isCodDisabledForOrder } from '@/utils/isCodDisabledForOrder';
import { useWidgetDndContextData } from '@/context/WidgetDndContext';
import { GlobalCfeLoader } from '@/components/WidgetMaker/WidgetDnD/GlobalCfeLoader';
import useUnselectablePaymentOptions from 'src/hooks/useUnselectablePaymentOptions';
import useStoreContactDetails from 'src/hooks/useStoreContactDetails';
import { getWhatAppRedirectionUrlCODRequest } from '@/utils/getWhatsAppRedirectionUrl';
import { canDisablePlaceOrder } from 'src/utils/canDisablePlaceOrder';

const DesktopPaymentModal = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();

  const orderHashFromParams = router.query.hash;
  const isPaymentPage = router.pathname.includes('/payment/');
  const isOnPendingPaymentPage = orderHashFromParams && isPaymentPage;

  const [state, setState] = useState({
    payOnDelivery: false,
    selectedOption: null,
    activePaymentMethods: [],
    modalVisible: false,
    showAuthModal: false,
  });
  const [toggleState, setToggleState] = useState(false);
  const [payAmount, setPayAmount] = useState(0);
  const [codCharge, setCodCharge] = useState(0);
  const [selectedPartialPaymentMode, setSelectedPartialPaymentMode] = useState(
    PARTIAL_PAYMENT_OPTION.FULL_PAYMENT
  );

  const { partialPaymentFeatureStatus } = useFeatureLocks();
  const { storePhone } = useStoreContactDetails();
  const partialPaymentFeatureVisible = partialPaymentFeatureStatus?.isVisible;

  const {
    theme,
    paymentModalVisibility,
    orderDetails,
    storeInfo,
    paymentMethods,
    downPaymentMethods,
    otherMethods,
    cart,
    paymentOptionsWithTxnCharge,
    paymentMethod,
    minEmiAmount,
    userReducer,
    userData,
    partial_payment_amount,
    paymentModalOptionsVisibility,
    services,
  } = useSelector((state) => {
    const userReducer = state.userReducer;
    return {
      theme: state.storeReducer.store.theme,
      services: state.storeReducer.store.services,
      paymentModalVisibility: state.commonReducer.paymentModalVisibility,
      orderDetails: state.orderReducer.orderDetails,
      storeInfo: state.storeReducer.store,
      paymentMethods: state.paymentReducer.methods,
      downPaymentMethods: state.paymentReducer.downPaymentMethods,
      otherMethods: state.paymentReducer.otherMethods,
      cart: state.cartReducer,
      paymentOptionsWithTxnCharge: state.paymentReducer.paymentOptionsWithTxnCharge,
      paymentMethod: state.paymentReducer.selectedPaymentOptionForMobilePayment,
      minEmiAmount: state.paymentReducer.min_emi_amount,
      userData: state.userReducer.data,
      userReducer,
      partial_payment_amount: state.paymentReducer.partial_payment_amount,
      paymentModalOptionsVisibility: state.commonReducer.paymentModalOptionsVisibility,
    };
  });

  const { finalUnselectablePaymentOptions } = useUnselectablePaymentOptions();

  const checkIfMDRActive = storeInfo?.services?.mdr_flag === 1;

  const requireUserLogin = useMemo(() => {
    if (!isOnPendingPaymentPage || IS_SERVER || orderDetails?.phone == '0000000000')
      return false;
    const authToken = localStorage?.getItem('auth_token');
    if (authToken && !userData?.phone) return false;
    if (!userReducer?.isLogin) return true;
    if (orderDetails?.phone) return userData?.phone !== orderDetails?.phone;
    return false;
  }, [orderDetails, userReducer, IS_SERVER]);

  useEffect(() => {
    if (isOnPendingPaymentPage) {
      const authToken = localStorage?.getItem('auth_token');
      if (authToken && !userData?.phone) {
        const authData = {
          auth_token: authToken,
          service: '',
        };
        dispatch(authenticateUser(authData));
      }
    }

    setCodCharge(_getCODCharges(orderDetails, services));
  }, []);

  useEffect(() => {
    if (requireUserLogin && !state.showAuthModal) {
      toggleAuthModal();
    }
  }, [requireUserLogin, state.showAuthModal]);

  useEffect(() => {
    dispatch(setPaymentModalOptionsVisiblity(true));
    if (paymentModalVisibility && !state.modalVisible) {
      storeInfo?.store_id &&
        orderDetails?.order_hash &&
        dispatch(
          fetchPaymentMethods({
            store_id: storeInfo?.store_id,
            hash: orderDetails?.order_hash,
          })
        );
      if (orderDetails?.parent_order_id !== 0 && orderDetails?.partial_payment_type > 0) {
        dispatch(fetchOrderDetailByID({ order_id: orderDetails?.parent_order_id }));
      }
      setState((state) => ({
        ...state,
        modalVisible: true,
      }));
    }
    if (!paymentModalVisibility) {
      setState((state) => ({
        ...state,
        modalVisible: false,
      }));
    }
    setCodCharge(_getCODCharges(orderDetails, services));
  }, [paymentModalVisibility, storeInfo, orderDetails]);

  useEffect(() => {
    if (paymentMethod && Object.keys(paymentMethod).length) {
      setPayAmount(paymentMethod.pay_amount);
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (paymentModalVisibility && cart.payment_offer?.payment_method) {
      const paymentMethod =
        cart.payment_offer?.payment_method === PAYMENT_TYPE_CONSTANTS.DEBIT_CARD ||
        cart.payment_offer?.payment_method === PAYMENT_TYPE_CONSTANTS.CREDIT_CARD
          ? PAYMENT_TYPE_CONSTANTS.CARD
          : cart.payment_offer?.payment_method;
      submitPayment(paymentMethod, true); // UPI payment activation
    }
  }, [paymentModalVisibility]);

  useEffect(() => {
    const tempState = {};
    tempState.activePaymentMethods = [];
    tempState.selectedOption = null;
    let methods = [];
    /**
     * If mdr_flag == 1 (customer pays transaction charges)
     * get payment methods from paymentOptionsWithTxnCharge key in paymentReducer
     * otherwise go with normal flow
     */
    if (checkIfMDRActive && checkIfObjectExists(paymentOptionsWithTxnCharge ?? {})) {
      const { down_payment_modes, others, payment_modes } = paymentOptionsWithTxnCharge;
      /**
       * Take all the keys from paymentOptionsWithTxnCharge
       * and take out only the value of 'payment_option_name' key
       */
      methods = [
        ...(payment_modes || []),
        ...(down_payment_modes || []),
        ...(others || []),
      ].map((method) => {
        if (method.hasOwnProperty('payment_option_name'))
          return method['payment_option_name'];
      });
    } else if (
      paymentMethods?.length ||
      downPaymentMethods?.length ||
      otherMethods?.length
    ) {
      methods = [...paymentMethods, ...downPaymentMethods, ...otherMethods];
    }

    if (
      methods.includes(PAYMENT_TYPE_CONSTANTS.GOOGLE_PAY) ||
      methods.includes(PAYMENT_TYPE_CONSTANTS.PHONEPE) ||
      methods.includes(PAYMENT_TYPE_CONSTANTS.PAYTM_UPI)
    ) {
      tempState.activePaymentMethods.push(ACTIVE_PAYMENT_METHOD.UPI);
      if (paymentModalVisibility && checkForInvalidAmount()) {
        tempState.selectedOption = ACTIVE_PAYMENT_METHOD.CASH;
      } else tempState.selectedOption = ACTIVE_PAYMENT_METHOD.UPI;
      /**
       * If mdr_flag == 1, send data of first payment_method
       * to reducer to be used later
       */
      if (checkIfMDRActive) {
        const { payment_modes } = paymentOptionsWithTxnCharge;
        if (payment_modes?.length)
          dispatch(setCustomerTransactionPaymentMethod(payment_modes[0] ?? {}));
      }
    }
    /**
     * if mdr_flag == 1, show CC and DC
     * otherwise show common CC-DC component
     */
    if (
      checkIfMDRActive &&
      (methods.includes(PAYMENT_TYPE_CONSTANTS.CREDIT_CARD) ||
        methods.includes(PAYMENT_TYPE_CONSTANTS.DEBIT_CARD))
    ) {
      /**
       * when mdr_flag == 1, CC and DC have separate txnm charges
       * so have to be shown separately
       */
      if (methods.includes(PAYMENT_TYPE_CONSTANTS.CREDIT_CARD))
        tempState.activePaymentMethods.push(ACTIVE_PAYMENT_METHOD.CREDIT_CARD);
      if (methods.includes(PAYMENT_TYPE_CONSTANTS.DEBIT_CARD))
        tempState.activePaymentMethods.push(ACTIVE_PAYMENT_METHOD.DEBIT_CARD);
    } else {
      if (methods.includes(PAYMENT_TYPE_CONSTANTS.CARD)) {
        tempState.activePaymentMethods.push(ACTIVE_PAYMENT_METHOD.CARD);
      }
    }

    if (methods.includes(PAYMENT_TYPE_CONSTANTS.LAZYPAY)) {
      tempState.activePaymentMethods.push(ACTIVE_PAYMENT_METHOD.LAZYPAY);
    }

    if (methods.includes(PAYMENT_TYPE_CONSTANTS.COD)) {
      tempState.activePaymentMethods.push(ACTIVE_PAYMENT_METHOD.CASH);
    }
    if (methods.includes(PAYMENT_TYPE_CONSTANTS.EMI)) {
      tempState.activePaymentMethods.push(ACTIVE_PAYMENT_METHOD.EMI);
    }
    if (methods.includes(PAYMENT_TYPE_CONSTANTS.MOBIKWIK)) {
      tempState.activePaymentMethods.push(ACTIVE_PAYMENT_METHOD.MOBIKWIK);
    }
    setState((state) => ({ ...state, ...tempState }));
  }, [paymentMethods, downPaymentMethods, otherMethods, paymentOptionsWithTxnCharge]);

  useEffect(() => {
    if (paymentModalVisibility && !cart?.payment_offer?.payment_method) {
      dispatch(
        pushEventsLog({
          event_name: 'Cx_Payment_Init',
          data: {
            store_id: storeInfo?.store_id,
            domain: storeInfo?.store_info?.domain,
            device: deviceWidth <= MAX_MOBILE_WIDTH ? 'Mobile' : 'Desktop',
            cx_number: userData?.phone,
          },
        })
      );
    }
  }, [paymentModalVisibility, cart?.payment_offer?.payment_method]);

  const toggleAuthModal = () => {
    if (!requireUserLogin) {
      setState({ ...state, showAuthModal: false });
    } else {
      setState((state) => ({ ...state, showAuthModal: !state.showAuthModal }));
    }
  };

  const submitPayment = (method, isPaymentPromoPresent = false) => {
    const payload = {
      orderID: orderDetails?.order_hash,
      paymentMethod: method,
      phone: userData?.phone,
      merchantID: orderDetails?.merchant_id,
      deviceType: getDeviceInfo().OSName,
      browserType: getDeviceInfo().BrowserName,
      userAgent: getDeviceInfo().userAgent,
      source: 'longtail',
      merchantName: `Digital Showroom - ${storeInfo?.store_info?.name}`,
    };
    dispatch(savePaymentInfo(payload));
    if (isPaymentPromoPresent) {
      payload['promoCode'] = cart?.payment_offer?.promo_code;
    }
    if (method === PAYMENT_TYPE_CONSTANTS.CARD && !isPaymentPromoPresent) {
      router.push(
        getRoute(
          PATH.cardPayment(orderDetails?.order_hash),
          storeInfo?.store_info?.domain
        )
      );
      return;
    }
    if (method === PAYMENT_TYPE_CONSTANTS.CARD && isPaymentPromoPresent) {
      const cardDetails = JSON.parse(
        getEncrypDecryptStr(JSON.parse(localStorage?.getItem('dscc')), 'decrypt')
      );
      payload['cNum'] = cardDetails.cNum;
      payload['cCvv'] = cardDetails.cCvv;
      payload['cName'] = cardDetails.cName;
      payload['cExpMonth'] = cardDetails.cExpMonth;
      payload['cExpYear'] = cardDetails.cExpYear;
    }
    dispatch(initPayment(payload, router));
    localStorage?.setItem('orderID', orderDetails?.order_hash);
  };

  const onClose = () => {
    if (props.behaveAsPaymentPage) {
      return;
    }
    setSelectedPartialPaymentMode(PARTIAL_PAYMENT_OPTION.FULL_PAYMENT);
    dispatch(setPaymentModalVisiblity(false));
    dispatch(setPaymentModalOptionsVisiblity(true));
  };

  const toggleSelectedOption = (option, txnType) => {
    let mdrValid = true;
    if (option === state.selectedOption) return;
    if (checkForInvalidAmount()) {
      toastNotifyError(getPrepaidInvalidAmountMessage(getStoreType()));
      return;
    }

    /*
      MDR and ERI options are exclusive now.
      So if EMI option is selected then, 
      prePaymentCheckMDRTransactions is now not required.

      if emi payment method selected, then we can avoid using the 
      prePaymentCheckMDRTransactions api.
    */
    if (
      checkIfMDRActive &&
      checkIfObjectExists(txnType)
      // option !== ACTIVE_PAYMENT_METHOD.EMI
    ) {
      const payload = {
        order_hash: orderDetails?.order_hash,
        store_id: orderDetails?.store_id,
        payment_option: txnType,
      };
      const callback = (status) => {
        mdrValid = status;
        const oldSelectedState = { ...state };
        if (!mdrValid) {
          setState(oldSelectedState);
        }
      };
      batch(() => {
        dispatch(setCustomerTransactionPaymentMethod(txnType));
        dispatch(prePaymentCheckMDRTransactions(payload, callback));
      });
    }
    if (mdrValid) {
      if (option === ACTIVE_PAYMENT_METHOD.LAZYPAY) {
        const payload = {
          orderID: orderDetails?.order_hash,
          paymentMethod: 'lazyPay',
          merchantID: orderDetails?.merchant_id,
          deviceType: getDeviceInfo().OSName,
          browserType: getDeviceInfo().BrowserName,
          userAgent: getDeviceInfo().userAgent,
          source: 'longtail',
          merchantName: `Digital Showroom - ${storeInfo?.store_info?.name}`,
        };

        dispatch(savePaymentInfo(payload));

        payload.phone = orderDetails.phone;
        payload.amount = checkIfMDRActive ? txnType.pay_amount : orderDetails.amount;
        payload.store_id = orderDetails.store_id;
        dispatch(initLazyPayPayment(payload));
      }
      setState((state) => ({ ...state, selectedOption: option }));
    }
  };

  // const paymentActivation = () => {

  // }

  /**
   * onHandler function for
   * Payment of type CASH
   */
  const onCashOptionHandle = () => {
    if (canDisablePlaceOrder()) {
      toastNotifyError(
        'This is a demo website, you need a live version to proceed this action.'
      );
      return;
    }
    if (
      isCodDisabledForOrder() &&
      orderDetails?.buyer_pay_value <= MINIMUM_ORDER_AMOUNT
    ) {
      toastNotifyError(`Cannot place order below ${MINIMUM_ORDER_AMOUNT}`);
      return;
    }
    // Check if the payment is for prepaid order
    if (
      storeInfo?.services?.payment_method === PREPAID_POSTPAID_METHODS.BOTH &&
      orderDetails.prepaid_flag === 1
    ) {
      dispatch(updateOrderState(orderDetails?.order_hash, router));
    } else {
      dispatch(setPaymentModalVisiblity(false));
      if (storePhone) {
        // Create whatsapp intent for web and open in next tab
        window.open(
          getWhatAppRedirectionUrlCODRequest(
            orderDetails?.buyer_pay_value,
            orderDetails?.order_id
          ),
          '_blank'
        );
      }
    }
  };

  /**
   * function return payment amount strip
   * @return {JSX}
   */
  const _renderPaymentAmtStrip = () => {
    return (
      <article className="pv3 bg-black-10 fw6 f4 br--top br4 tc flex flex-column items-center justiyf-center">
        Pay ₹ {checkIfMDRActive ? payAmount : orderDetails.buyer_pay_value}
        {[ACTIVE_PAYMENT_METHOD.CREDIT_CARD].includes(state.selectedOption) && (
          <div className="card-payment-exceptions mt2">
            For Amex Cards, convenience fee will be 2.7%
          </div>
        )}
      </article>
    );
  };

  const returnTransactionPercent = (value) => {
    const { payment_modes, others } = paymentOptionsWithTxnCharge;
    const combinedArr = [...(payment_modes || []), ...(others || [])];
    const txnType = combinedArr.find(
      (item) => item.payment_option_name.toLowerCase() === value.toLowerCase()
    );
    return txnType?.transaction_charge || '0';
  };

  const sendTransactionTypeToState = (value) => {
    const searchValue =
      value === ACTIVE_PAYMENT_METHOD.UPI
        ? PAYMENT_TYPE_CONSTANTS.GOOGLE_PAY
        : value.toLowerCase();
    if (paymentOptionsWithTxnCharge && Object.keys(paymentOptionsWithTxnCharge).length) {
      const { payment_modes, others } = paymentOptionsWithTxnCharge;
      const combinedArr = [...(payment_modes || []), ...(others || [])];
      const txnType = combinedArr.find(
        (item) => item.payment_option_name.toLowerCase() === searchValue
      );
      return txnType || null;
    }
  };

  const CashPayment = () => {
    if (Number(services?.cod_charge_flag) > COD_CHARGE_TYPE.FREE) {
      return (
        <div
          className={`extra-cod-charge-container ${
            state.selectedOption === ACTIVE_PAYMENT_METHOD.CASH ? 'db' : 'dn'
          }`}
        >
          <div className="header">
            <div className="header-text">
              {`Total amount to be paid: ₹${
                Number(orderDetails?.pay_amount) + Number(codCharge)
              }`}
            </div>
          </div>
          <div className="cost-breakup">
            <div className="cost-breakup-info">
              <div className="cost-breakup-sub-info">
                <div className="cost-breakup-sub-info-detail">
                  <div className="cost-breakup-sub-info-detail-text">Item total</div>
                  <div className="cost-breakup-sub-info-detail-value">{`₹ ${getItemTotal(
                    orderDetails
                  )?.toFixed(2)}`}</div>
                </div>
                <div className="cost-breakup-sub-info-detail">
                  <div className="cost-breakup-sub-info-detail-text">Delivery Fees</div>
                  <div className="cost-breakup-sub-info-detail-value">{`₹ ${Number(
                    orderDetails?.delivery_charge
                  )}`}</div>
                </div>
                <div className="cost-breakup-sub-info-detail">
                  <div className="cost-breakup-sub-info-detail-text">
                    Extra Charges for Cash On Delivery
                  </div>
                  <div className="cost-breakup-sub-info-detail-value">{`₹ ${codCharge}`}</div>
                </div>
                {orderDetails?.extra_charges > 0 && (
                  <div className="cost-breakup-sub-info-detail">
                    <div className="cost-breakup-sub-info-detail-text">
                      {orderDetails?.extra_charge_name.substr(0, 20)}
                      {orderDetails?.extra_charge_name.length > 20 && '...'}
                    </div>
                    <div className="cost-breakup-sub-info-detail-value">{`₹ ${orderDetails?.extra_charges}`}</div>
                  </div>
                )}
                {orderDetails?.discount > 0 && (
                  <div className="cost-breakup-sub-info-detail">
                    <div className="cost-breakup-sub-info-detail-text">Discount</div>
                    <div className="cost-breakup-sub-info-detail-value">{`- ₹ ${orderDetails?.discount}`}</div>
                  </div>
                )}
                {orderDetails?.promo_discount > 0 && (
                  <div className="cost-breakup-sub-info-detail">
                    <div className="cost-breakup-sub-info-detail-text">
                      Promo Discount
                    </div>
                    <div className="cost-breakup-sub-info-detail-value">{`- ₹ ${orderDetails?.promo_discount}`}</div>
                  </div>
                )}
                {isOrderGstExcluded() && (
                  <div className="cost-breakup-sub-info-detail">
                    <div className="cost-breakup-sub-info-detail-text">GST</div>
                    <div className="cost-breakup-sub-info-detail-value">{`₹ ${orderDetails?.total_gst_paid}`}</div>
                  </div>
                )}
              </div>
              <div
                className="break-line"
                style={{ backgroundColor: 'black', height: '1px', width: '100%' }}
              ></div>
              <div className="grand-total">
                <div className="grand-total-text">
                  Grand Total
                  {isOrderGstIncluded() && (
                    <span className="incl-gst responsive-minor-text fw4 ml6px">
                      (Incl. ₹ {orderDetails?.total_gst_paid} GST)
                    </span>
                  )}
                </div>
                <div className="grand-total-value">
                  {`₹ ${Number(orderDetails?.pay_amount) + Number(codCharge)}`}
                </div>
              </div>
            </div>
          </div>
          <div className="confirm-cod-btn pointer" onClick={() => onCashOptionHandle()}>
            <div className="confirm-cod-btn-text">Place Cash on Delivery Order</div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`${
          state.selectedOption === ACTIVE_PAYMENT_METHOD.CASH ? 'flex' : 'dn'
        } flex-column justify-center items-center h-100`}
      >
        <div className="tc f6 pb3">
          {orderDetails?.prepaid_flag === 1 && (
            <div>
              <span>You have to Pay</span>
              <span className="fw6 mh2">₹ {orderDetails?.buyer_pay_value}</span>
              <span>
                at the time of{' '}
                {orderDetails?.order_type === ORDER_TYPE.SERVICE
                  ? 'Service'
                  : orderDetails.order_type === ORDER_TYPE.DELIVERY
                    ? 'Delivery'
                    : 'Pick Up'}
              </span>
            </div>
          )}
        </div>
        <button
          className="pv3 bn white fw6 w-70"
          onClick={onCashOptionHandle}
          style={{
            backgroundColor: theme?.colors?.primary_color
              ? theme?.colors?.primary_color
              : '#EA5151',
            borderRadius: theme ? `${roundnessCalculator(theme?.roundness)}` : '8px',
          }}
        >
          {orderDetails?.prepaid_flag === 1
            ? orderDetails?.order_type === ORDER_TYPE.SERVICE
              ? 'Confirm Booking'
              : 'Place Order'
            : 'Request Cash Payment'}
        </button>
      </div>
    );
  };

  /**
   * Function check which payment option
   * is checked and return thier view
   * @param {String} type
   * @return {JSX}
   */
  const _renderPaymentBlock = (type) => {
    switch (type) {
      case ACTIVE_PAYMENT_METHOD.UPI:
        return (
          <div
            className={state.selectedOption === ACTIVE_PAYMENT_METHOD.UPI ? 'db' : 'dn'}
          >
            {_renderPaymentAmtStrip()}
            {!checkForInvalidAmount() && (
              <QRPaymentUPI
                partial_payment={toggleState}
                txnData={
                  sendTransactionTypeToState(PAYMENT_TYPE_CONSTANTS.PHONEPE) // Use any UPI company for transaction amount
                }
              />
            )}
          </div>
        );
      case ACTIVE_PAYMENT_METHOD.CREDIT_CARD:
        return (
          <div
            className={
              state.selectedOption === ACTIVE_PAYMENT_METHOD.CREDIT_CARD ? 'db' : 'dn'
            }
          >
            {_renderPaymentAmtStrip()}
            <CardPaymentBox />
          </div>
        );
      case ACTIVE_PAYMENT_METHOD.DEBIT_CARD:
        return (
          <div
            className={
              state.selectedOption === ACTIVE_PAYMENT_METHOD.DEBIT_CARD ? 'db' : 'dn'
            }
          >
            {_renderPaymentAmtStrip()}
            <CardPaymentBox />
          </div>
        );
      case ACTIVE_PAYMENT_METHOD.CARD:
        return (
          <div
            className={state.selectedOption === ACTIVE_PAYMENT_METHOD.CARD ? 'db' : 'dn'}
          >
            {_renderPaymentAmtStrip()}
            <CardPaymentBox />
          </div>
        );
      case ACTIVE_PAYMENT_METHOD.LAZYPAY:
        return (
          <div
            className={
              state.selectedOption === ACTIVE_PAYMENT_METHOD.LAZYPAY ? 'db' : 'dn'
            }
          >
            {_renderPaymentAmtStrip()}
            <div className="pa3">
              <LazyPayAuth isDesktop={true} />
            </div>
          </div>
        );
      case ACTIVE_PAYMENT_METHOD.CASH:
        return <CashPayment />;
      case ACTIVE_PAYMENT_METHOD.EMI:
        return (
          <div
            className={state.selectedOption === ACTIVE_PAYMENT_METHOD.EMI ? 'db' : 'dn'}
          >
            <div className="pa4">
              <EmiCardPayment />
            </div>
          </div>
        );
      case ACTIVE_PAYMENT_METHOD.MOBIKWIK:
        return (
          <div
            className={
              state.selectedOption === ACTIVE_PAYMENT_METHOD.MOBIKWIK ? 'db' : 'dn'
            }
          >
            {_renderPaymentAmtStrip()}
            <MobikwikPayment
              onClick={() => {
                submitPayment(PAYMENT_TYPE_CONSTANTS.MOBIKWIK);
              }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  /**
   * Function for getting the
   * Payment option item card
   * @param {Object} param
   * @return {JSX}
   */

  const _renderPaymentOptionItem = ({
    title,
    option,
    images,
    hasImages,
    transactionAmt = false,
    txnType = null,
    customClasses = '',
  }) => {
    return (
      <article
        className={`option-card ${customClasses} ${state.selectedOption === option ? 'selected' : ''}`}
        onClick={() => toggleSelectedOption(option, txnType)}
      >
        <div className="flex">
          <div className="input-wrap">
            <CustomRadio
              className="slimy-green-color"
              type="radio"
              checked={state.selectedOption === option}
              aria-checked={state.selectedOption === option}
            />
          </div>
          <div className="content-wrap">
            <article className="fw5">{title}</article>
            {option === ACTIVE_PAYMENT_METHOD.CASH &&
              services?.cod_charge_flag > COD_CHARGE_TYPE.FREE && (
                <div className="extra-cod-charges" style={{ marginTop: '4px' }}>
                  {`Extra Charges ₹ ${codCharge}`}
                </div>
              )}
            {hasImages && (
              <article className="icons-wrap">
                {images.map((image, idx) => (
                  <span key={`option-icons-${idx}`}>
                    <img src={image}></img>
                  </span>
                ))}
              </article>
            )}
          </div>
        </div>
        {minEmiAmount > 0 &&
        ACTIVE_PAYMENT_METHOD.EMI.substring(1).toLowerCase() === title.toLowerCase() ? (
          <div className="transaction-charge-text" style={{ marginTop: 0 }}>
            EMI starts at ₹{minEmiAmount}/month
          </div>
        ) : null}
        {checkIfMDRActive && (transactionAmt || transactionAmt === 0) ? (
          <div className="transaction-charge-text">{transactionAmt}% convenience fee</div>
        ) : null}
      </article>
    );
  };

  const _renderUnselectablePaymentOptions = () => {
    const isCodDisabledDueToCoupon = finalUnselectablePaymentOptions?.includes(
      PAYMENT_TYPE_CONSTANTS.COD
    );
    return (
      !!finalUnselectablePaymentOptions.length && (
        <>
          <div className="f6 pb3">Other Modes</div>
          {finalUnselectablePaymentOptions.map((paymentOption, index) => (
            <div key={index} className={'tw-pointer-events-none tw-opacity-40'}>
              {_renderPaymentOptionItem({
                title: getCustomCtaText('other_payment_modes_cta'),
                option: paymentOption,
                customClasses: '!tw-m-0',
              })}
              {isCodDisabledDueToCoupon && (
                <p className="tw-m-0 tw-pt-[8px] tw-text-[11px]/[15px] tw-text-[#111C36CC] ">
                  You cannot select this option, the applied offer is not applicable on
                  ‘Pay on Delivery’ orders.
                </p>
              )}
            </div>
          ))}
        </>
      )
    );
  };

  const cardRenderSection = () => {
    if (checkIfMDRActive) {
      return (
        <>
          {state.activePaymentMethods.includes(ACTIVE_PAYMENT_METHOD.CREDIT_CARD) &&
            _renderPaymentOptionItem({
              title: 'Credit Card',
              option: ACTIVE_PAYMENT_METHOD.CREDIT_CARD,
              hasImages: true,
              images: [
                '/assets/images/mastercard@2x.png',
                '/assets/images/visa-logo-png-transparent@2x.png',
              ],
              transactionAmt: returnTransactionPercent(ACTIVE_PAYMENT_METHOD.CREDIT_CARD),
              txnType: sendTransactionTypeToState(ACTIVE_PAYMENT_METHOD.CREDIT_CARD),
            })}
          {state.activePaymentMethods.includes(ACTIVE_PAYMENT_METHOD.DEBIT_CARD) &&
            _renderPaymentOptionItem({
              title: 'Debit Card',
              option: ACTIVE_PAYMENT_METHOD.DEBIT_CARD,
              hasImages: true,
              images: [
                '/assets/images/mastercard@2x.png',
                '/assets/images/visa-logo-png-transparent@2x.png',
              ],
              transactionAmt: returnTransactionPercent(ACTIVE_PAYMENT_METHOD.DEBIT_CARD),
              txnType: sendTransactionTypeToState(ACTIVE_PAYMENT_METHOD.DEBIT_CARD),
            })}
        </>
      );
    } else {
      return (
        state.activePaymentMethods.includes(ACTIVE_PAYMENT_METHOD.CARD) &&
        _renderPaymentOptionItem({
          title: 'Credit & Debit Cards',
          option: ACTIVE_PAYMENT_METHOD.CARD,
          hasImages: true,
          images: [
            '/assets/images/mastercard@2x.png',
            '/assets/images/visa-logo-png-transparent@2x.png',
          ],
        })
      );
    }
  };

  const checkForCashPayment = () =>
    state.activePaymentMethods.includes(ACTIVE_PAYMENT_METHOD.CASH) &&
    storeInfo?.services?.payment_method !== PREPAID_POSTPAID_METHODS.PREPAID &&
    selectedPartialPaymentMode !== PARTIAL_PAYMENT_OPTION.PARTIAL_PAYMENT;

  const handleInputRadioClick = (type) => {
    // if user is clicking same input radio again n again, don't allow any network call.
    if (type === selectedPartialPaymentMode) return;

    dispatch(setPaymentModalOptionsVisiblity(false));
    const payload = {
      store_id: storeInfo?.store_id,
      order_id: orderDetails?.order_id,
      part_payment_option: type,
      pay_amount: orderDetails?.pay_amount,
    };
    dispatch(
      togglePartialPaymentMode(
        payload,
        () => {
          setToggleState(!toggleState);
          dispatch(
            fetchOrderByHash({
              order_hash: orderDetails.order_hash,
              isPrepaid: 1,
            })
          );
          dispatch(
            fetchPaymentMethods({
              store_id: storeInfo?.store_id,
              hash: orderDetails?.order_hash,
            })
          );
          dispatch(setPaymentModalOptionsVisiblity(true));
        },
        () => {
          setSelectedPartialPaymentMode(type);
        }
      )
    );
  };

  const isPartialPaymentToggleNotAllowed = () => {
    if (partial_payment_amount < MINIMUM_ORDER_AMOUNT) {
      return true;
    }
    if (checkIfMDRActive) {
      return partial_payment_amount >= payAmount;
    } else {
      return partial_payment_amount >= orderDetails.buyer_pay_value;
    }
  };

  const renderPartialPaymentComponent = () => {
    return (
      <div className="partial-payment-section">
        <h2 className="title">Select a payment method</h2>
        <div className="radio-input-wrapper">
          <div className="radio-input-item">
            <CustomRadio
              type="radio"
              checked={selectedPartialPaymentMode === PARTIAL_PAYMENT_OPTION.FULL_PAYMENT}
              aria-checked={
                selectedPartialPaymentMode === PARTIAL_PAYMENT_OPTION.FULL_PAYMENT
              }
              style={{
                width: '20px',
                height: '20px',
                color: '#3D6AFD',
                cursor: 'pointer',
                border:
                  selectedPartialPaymentMode === PARTIAL_PAYMENT_OPTION.FULL_PAYMENT
                    ? ''
                    : '0.15em solid #D4D4D5',
              }}
              onClick={() => handleInputRadioClick(PARTIAL_PAYMENT_OPTION.FULL_PAYMENT)}
            />
            <div className="radio-content-wrap">
              <article className="fw5">Full Payment</article>
            </div>
          </div>
          <div className="radio-input-item">
            <CustomRadio
              type="radio"
              checked={
                selectedPartialPaymentMode === PARTIAL_PAYMENT_OPTION.PARTIAL_PAYMENT
              }
              aria-checked={
                selectedPartialPaymentMode === PARTIAL_PAYMENT_OPTION.PARTIAL_PAYMENT
              }
              style={{
                width: '20px',
                height: '20px',
                color: '#3D6AFD',
                cursor: `${
                  isPartialPaymentToggleNotAllowed() ? 'not-allowed' : 'pointer'
                }`,
                border:
                  selectedPartialPaymentMode === PARTIAL_PAYMENT_OPTION.PARTIAL_PAYMENT
                    ? ''
                    : '0.15em solid #D4D4D5',
              }}
              onClick={() =>
                handleInputRadioClick(PARTIAL_PAYMENT_OPTION.PARTIAL_PAYMENT)
              }
              disabled={isPartialPaymentToggleNotAllowed()}
            />
            <div className="radio-content-wrap">
              <article className="fw5">
                {storeInfo.services.partial_payment_flag === 1
                  ? `${storeInfo.services.partial_payment_value}% `
                  : ''}
                Partial Payment - Rs. {partial_payment_amount}
              </article>
              <img
                loading="lazy"
                src="/assets/icons/tooltipGrayIcon.svg"
                data-tip
                data-for="partial-payment-info"
              />
              <ReactTooltip
                id="partial-payment-info"
                place="bottom"
                effect="solid"
                multiline={true}
                padding="8px"
                disable={false}
                arrowColor="#4F4F4F"
              >
                <p className="mv0 fw5 f12px">
                  Choose this option to make a partial <br /> payment. The remaining
                  amount will be <br /> collected at the time of delivery/pickup
                </p>
              </ReactTooltip>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {paymentModalVisibility && !cart?.payment_offer?.payment_method ? (
        <Modal
          visible={paymentModalVisibility}
          className={
            'bottom-modal desktopPaymentModal ' +
            (props.behaveAsPaymentPage ? 'desktopPaymentPage' : '')
          }
          animation={'slideUp'}
          customStyles={{
            maxWidth: '1000px',
            width: '100%',
          }}
          onClose={onClose}
        >
          {props.behaveAsPaymentPage ? null : (
            <span className="closeicon pointer" onClick={onClose}>
              <img loading="lazy" src="/assets/images/cancel.png" alt="cancel" />
            </span>
          )}
          {/* If either partial payment is not enabled by Mx or the order is of type child order, then don't render partialPayment component */}
          {partialPaymentFeatureVisible &&
          storeInfo?.services?.partial_payment_flag &&
          orderDetails.prepaid_flag > 0 &&
          !(
            orderDetails.parent_order_id !== 0 && orderDetails.partial_payment_type > 0
          ) ? (
            renderPartialPaymentComponent()
          ) : (
            <div className="f4 fw6 pv3 ph2">Select Payment Method</div>
          )}
          {!paymentModalOptionsVisibility ? (
            <div
              style={{
                height: '492px',
                background: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <GlobalCfeLoader
                type={globalStyle?.appLoader?.loaderId || 'ellipsis'}
                color={globalStyle?.appLoader?.color}
              />
              {/* <LoaderIcon /> */}
            </div>
          ) : (
            <div className="content-grid pb4 ph2 pt3">
              <section className="pv2 overflow-y-auto">
                {state.activePaymentMethods.includes(ACTIVE_PAYMENT_METHOD.UPI) &&
                  _renderPaymentOptionItem({
                    title: 'UPI Payments',
                    option: ACTIVE_PAYMENT_METHOD.UPI,
                    hasImages: true,
                    images: [
                      '/assets/images/ic_paytm@2x.png',
                      '/assets/images/ic_gpay@2x.png',
                      '/assets/images/ic_phonep@2x.png',
                    ],
                    transactionAmt: returnTransactionPercent(
                      PAYMENT_TYPE_CONSTANTS.PHONEPE // Use any UPI company for transaction amount
                    ),
                    txnType: sendTransactionTypeToState(PAYMENT_TYPE_CONSTANTS.PHONEPE),
                  })}
                {cardRenderSection()}
                {state.activePaymentMethods.includes(ACTIVE_PAYMENT_METHOD.LAZYPAY) &&
                  _renderPaymentOptionItem({
                    title: 'LazyPay',
                    option: ACTIVE_PAYMENT_METHOD.LAZYPAY,
                    hasImages: true,
                    images: ['/assets/images/lazypay_.png'],
                    transactionAmt: returnTransactionPercent(
                      ACTIVE_PAYMENT_METHOD.LAZYPAY
                    ),
                    txnType: sendTransactionTypeToState(ACTIVE_PAYMENT_METHOD.LAZYPAY),
                  })}
                {state.activePaymentMethods.includes(ACTIVE_PAYMENT_METHOD.MOBIKWIK) &&
                  _renderPaymentOptionItem({
                    title: 'Mobikwik',
                    option: ACTIVE_PAYMENT_METHOD.MOBIKWIK,
                    hasImages: true,
                    images: [`https://cdn.dotpe.in/kiranaStatic/icons/mobikwik_icon.png`],
                    transactionAmt: returnTransactionPercent(
                      ACTIVE_PAYMENT_METHOD.MOBIKWIK
                    ),
                    txnType: sendTransactionTypeToState(ACTIVE_PAYMENT_METHOD.MOBIKWIK),
                  })}
                {state.activePaymentMethods.includes(ACTIVE_PAYMENT_METHOD.EMI) &&
                  _renderPaymentOptionItem({
                    title: 'EMI',
                    option: ACTIVE_PAYMENT_METHOD.EMI,
                    hasImages: true,
                    images: [],
                    transactionAmt: 0,
                    txnType: sendTransactionTypeToState(ACTIVE_PAYMENT_METHOD.EMI),
                  })}
                {state.activePaymentMethods.includes(ACTIVE_PAYMENT_METHOD.CASH) &&
                storeInfo.services?.payment_method !== 1 &&
                checkForCashPayment() ? (
                  <>
                    <div className="f6 pb3">Other Modes</div>
                    {_renderPaymentOptionItem({
                      title: getCustomCtaText('other_payment_modes_cta'),
                      option: ACTIVE_PAYMENT_METHOD.CASH,
                      txnType: sendTransactionTypeToState(PAYMENT_TYPE_CONSTANTS.COD),
                      customClasses: '!tw-m-0',
                    })}
                  </>
                ) : null}
                {_renderUnselectablePaymentOptions()}
              </section>
              <section className="br4 ba b--black-10 mh-100 overflow-y-auto">
                {state.activePaymentMethods.includes(ACTIVE_PAYMENT_METHOD.UPI) &&
                  _renderPaymentBlock(ACTIVE_PAYMENT_METHOD.UPI)}
                {checkIfMDRActive ? (
                  <>
                    {_renderPaymentBlock(ACTIVE_PAYMENT_METHOD.CREDIT_CARD)}
                    {_renderPaymentBlock(ACTIVE_PAYMENT_METHOD.DEBIT_CARD)}
                  </>
                ) : (
                  _renderPaymentBlock(ACTIVE_PAYMENT_METHOD.CARD)
                )}
                {_renderPaymentBlock(ACTIVE_PAYMENT_METHOD.LAZYPAY)}
                {_renderPaymentBlock(ACTIVE_PAYMENT_METHOD.CASH)}
                {_renderPaymentBlock(ACTIVE_PAYMENT_METHOD.EMI)}
                {_renderPaymentBlock(ACTIVE_PAYMENT_METHOD.MOBIKWIK)}
              </section>
            </div>
          )}
        </Modal>
      ) : (
        <></>
      )}
      <AuthModal
        isVisible={state.showAuthModal}
        toggleModal={toggleAuthModal}
        prefilledPhone={(isOnPendingPaymentPage && orderDetails?.phone) || null}
        sendOTPBeforeHand={isOnPendingPaymentPage}
      />
    </>
  );
};

export const getItemTotal = (orderDetails) => {
  return orderDetails?.items?.reduce((acc, curr) => {
    if (curr.item_status === 1) acc = acc + curr.amount;

    return acc;
  }, 0);
};

export function _getCODCharges(orderDetails, services) {
  const itemsTotal = getItemTotal(orderDetails);
  if (
    services?.cod_charge_flag === 0 ||
    (services?.cod_charge_offset_value !== 0 &&
      services?.cod_charge_offset_value < itemsTotal)
  ) {
    return 0;
  }
  if (services?.cod_charge_flag === 1) {
    return services?.cod_charge_value;
  } else if (services?.cod_charge_flag === 2) {
    return ((itemsTotal * services?.cod_charge_value) / 100).toFixed(0);
  }
  return 0;
}

export default DesktopPaymentModal;
