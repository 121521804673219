import Card from './Components/Card';
import { CARD_DEFAULT_CONFIGS } from './Constants';

const Testimonials = ({
  listPresentationConfig,
  cardFrameConfig,
  oneGridCellGeometry,
  isBuilder,
  isMobile,
}) => {
  // console.log('===>', listPresentationConfig, cardFrameConfig);
  const dataOrder = cardFrameConfig?.childElementOrder || [];
  const testimonialsData = cardFrameConfig?.testimonialsList || [];

  const config = CARD_DEFAULT_CONFIGS[listPresentationConfig?.layoutName];

  return (
    <>
      {testimonialsData.map((testimonialData) => (
        <Card
          key={testimonialsData?.id}
          dataOrder={dataOrder}
          testimonialData={testimonialData}
          listPresentationConfig={listPresentationConfig}
          cardFrameConfig={cardFrameConfig}
          oneGridCellGeometry={oneGridCellGeometry}
          isBuilder={isBuilder}
          isMobile={isMobile}
          {...config}
        />
      ))}
    </>
  );
};

export default Testimonials;
