export const ADMIN_WIDGETS_NAMES = {
  HEADER: 'NewHeader',
  FOOTER: 'NewAdminFooter',
  HERO_BANNER: 'NewHeroBanner',
  RICH_TEXT_WITH_IMAGE: 'RichTextWithImage',
  SLIDESHOW: 'Slideshow',
  RICH_TEXT: 'RichText',
  COLLECTIONS: 'Collections',
  INSTAGRAM_EMBED: 'InstagramEmbed',
  FEATURED_REELS: 'FeaturedReels',
  FEATURED_COLLECTIONS: 'FeaturedCollection',
  FEATURED_TAGS: 'FeaturedTag',
  FEATURED_CATEGORIES: 'FeaturedCategory',
  CATEGORY: 'Category',
  MULTI_COLUMN_WITH_ICON: 'MultiColumnWithIcon',
  MULTI_COLUMN_WITH_IMAGE: 'MultiColumnWithImage',
  LOGO_SLIDER: 'LogoSlider',
  BLOG_WIDGET: 'NewBlog',
};

// testimonial to be added later
export const ADMIN_WIDGETS_FOR_BRAND_WEBSITE = [
  ADMIN_WIDGETS_NAMES.HERO_BANNER,
  ADMIN_WIDGETS_NAMES.LOGO_SLIDER,
  ADMIN_WIDGETS_NAMES.FEATURED_REELS,
  ADMIN_WIDGETS_NAMES.MULTI_COLUMN_WITH_ICON,
  ADMIN_WIDGETS_NAMES.MULTI_COLUMN_WITH_IMAGE,
  ADMIN_WIDGETS_NAMES.RICH_TEXT,
  ADMIN_WIDGETS_NAMES.RICH_TEXT_WITH_IMAGE,
  ADMIN_WIDGETS_NAMES.BLOG_WIDGET,
  ADMIN_WIDGETS_NAMES.FOOTER,
];

export const ADMIN_WIDGETS = {
  HEADER: { type: 'NewHeader', label: 'Header', name: ADMIN_WIDGETS_NAMES.HEADER },
  FOOTER: { type: 'NewAdminFooter', label: 'Header', name: ADMIN_WIDGETS_NAMES.FOOTER },
  HERO_BANNER: {
    type: 'NewHeroBanner',
    name: ADMIN_WIDGETS_NAMES.HERO_BANNER,
    label: 'Hero Banner',
  },
  RICH_TEXT_WITH_IMAGE: {
    type: 'RichTextWithImage',
    name: ADMIN_WIDGETS_NAMES.RICH_TEXT_WITH_IMAGE,
    label: 'Rich text with image',
  },
  SLIDESHOW: {
    type: 'Slideshow',
    name: ADMIN_WIDGETS_NAMES.SLIDESHOW,
    label: 'Slideshow',
  },
  RICH_TEXT: {
    type: 'RichText',
    name: ADMIN_WIDGETS_NAMES.RICH_TEXT,
    label: 'Rich text',
  },
  COLLECTIONS: {
    type: 'Collections',
    name: ADMIN_WIDGETS_NAMES.COLLECTIONS,
    label: 'Collections',
  },
  INSTAGRAM_EMBED: {
    type: 'InstagramEmbed',
    name: ADMIN_WIDGETS_NAMES.INSTAGRAM_EMBED,
    label: 'InstagramEmbed',
  },
  FEATURED_REELS: {
    type: 'FeaturedReels',
    name: ADMIN_WIDGETS_NAMES.FEATURED_REELS,
    label: 'FeaturedReels',
  },
  FEATURED_COLLECTIONS: {
    type: 'FeaturedCollection',
    name: ADMIN_WIDGETS_NAMES.FEATURED_COLLECTIONS,
    label: 'Featured Collection',
  },
  FEATURED_CATEGORIES: {
    type: 'FeaturedCategory',
    name: ADMIN_WIDGETS_NAMES.FEATURED_CATEGORIES,
    label: 'Featured Category',
  },
  FEATURED_TAGS: {
    type: 'FeaturedTag',
    name: ADMIN_WIDGETS_NAMES.FEATURED_TAGS,
    label: 'Featured Tag',
  },
  CATEGORY: {
    type: 'Category',
    name: ADMIN_WIDGETS_NAMES.CATEGORY,
    label: 'Category',
  },
  MULTI_COLUMN_WITH_ICON: {
    type: 'MultiColumnWithIcon',
    name: ADMIN_WIDGETS_NAMES.MULTI_COLUMN_WITH_ICON,
    label: 'Multi column with icon',
  },
  MULTI_COLUMN_WITH_IMAGE: {
    type: 'MultiColumnWithImage',
    name: ADMIN_WIDGETS_NAMES.MULTI_COLUMN_WITH_IMAGE,
    label: 'Multi column with image',
  },
  LOGO_SLIDER: {
    type: 'LogoSlider',
    name: ADMIN_WIDGETS_NAMES.LOGO_SLIDER,
    label: 'Logo slider',
  },
  BLOG_WIDGET: {
    type: 'NewBlog',
    name: ADMIN_WIDGETS_NAMES.BLOG_WIDGET,
    label: 'New blog',
  },
  TESTIMONIALS: {
    type: 'Testimonials',
    name: 'Testimonials',
    label: 'Testimonials',
  },
};
