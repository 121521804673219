import { useDispatch, useSelector } from 'react-redux';
import { CartPromosContainer } from '../../../Common';
import { CustomPromo } from '../../Common';
import { applyPromo, togglePromoDetailModal } from 'src/redux/actions';
import { RootState } from 'src/redux/reducers';
import { toastNotifyError } from 'src/components/Common/Toast';
import { useContext } from 'react';
import { CartContext } from 'src/containers/Cart';

const StoreOffersSection = ({
  showAllPromos = false,
  cartStorePromos = [],
  cartData = {},
  setSuccessPopupState,
}) => {
  const dispatch = useDispatch();
  const { showAuthModal = null } = useContext(CartContext);
  const { storeId, isUserLoggedIn, userId } = useSelector((state: RootState) => ({
    storeId: state.storeReducer.store.store_id,
    isUserLoggedIn: state.userReducer?.isLogin,
    userId: state.userReducer?.data?.user_id,
  }));

  function onStoreOfferDetailClick(promoId) {
    dispatch(
      togglePromoDetailModal({
        show: true,
        promoId,
      })
    );
  }

  function onStoreOfferApplyClick(promoId) {
    if (!isUserLoggedIn) {
      showAuthModal?.();
      return;
    }

    const coupon = cartStorePromos?.find((promo) => promo?.id === promoId);

    const payload = {
      cart: {
        ...cartData,
        store_id: storeId,
        user_id: userId,
        store_offer: {
          ...(cartData.store_offer || {}),
          promo_id: coupon.id,
          promo_code: coupon.code,
        },
      },
    };

    dispatch(
      applyPromo(payload, (resp) => {
        if (resp.data.status) {
          const errorText = resp.data.data?.reason;
          if (!errorText) {
            setSuccessPopupState({
              show: true,
              appliedPromoCode: coupon.code,
            });
          } else {
            toastNotifyError(errorText);
          }
        } else {
          toastNotifyError(resp.message);
        }
      })
    );
  }

  const promosToShow = showAllPromos ? cartStorePromos : cartStorePromos.slice(0, 3);
  return (
    <div className="tw-flex tw-flex-col tw-gap-[24px]">
      <CustomPromo
        cartData={cartData}
        storeId={storeId}
        userId={userId}
        setSuccessPopupState={setSuccessPopupState}
        showAuthModal={showAuthModal}
      />
      <CartPromosContainer
        promos={promosToShow}
        onDetailClick={onStoreOfferDetailClick}
        onApplyClick={onStoreOfferApplyClick}
        isStorePromos={true}
      />
    </div>
  );
};

export default StoreOffersSection;
