import React, { MutableRefObject } from 'react';
import { getCardConfigurations } from '../CardFrameCollection/utils';
import { IOnScrollCarousel } from './components/types';

export const MAX_SECTION_WIDTH = 1600;
export const BLOG_SECTION_MAX_WIDTH = 1200;
export const MAX_CARD_HEIGHT = 680;
export const MAX_CARD_WIDTH = 680;
export const MIN_CARD_WIDTH = 120; // 170; // 100;
export const MIN_CARD_HEIGHT = 120; // 170; // 100;
export const MAX_NUM_ROWS_DESKTOP = 2; // 170; // 100;
export const MAX_NUM_ROWS_MOBILE_ONE_COL = 4;
export const MAX_NUM_ROWS_MOBILE_TWO_COL = 6;
export const INITIAL_SCROLL_PERCENTAGE = 20;
export const MULTI_COLUMN_MIN_WIDTH = 150;
export const MULTI_COLUMN_MIN_WIDTH_MOBILE = 100;
export const MIN_LOGO_SLIDER_CARD_WIDTH = 50;
export const MAX_PRODUCT_REEL_CARD_WIDTH_DESKTOP = 350;
export const MAX_PRODUCT_REEL_CARD_WIDTH_MOBILE = 220;
export const MIN_PRODUCT_REEL_CARD_WIDTH_DESKTOP = 172;
export const MIN_PRODUCT_REEL_CARD_WIDTH_MOBILE = 150;
export const MAX_PRODUCT_REEL_CARD_HEIGHT = 450;
export const MIN_PRODUCT_REEL_CARD_HEIGHT = 250;
export const MAX_LOGO_SLIDER_CARD_WIDTH = 160;
export const MAX_LOGO_SLIDER_HEIGHT = 84;
export const MIN_LOGO_SLIDER_HEIGHT = 50;

function getNumsOfColumn(isMobile, configData) {
  const {
    numColumnsDesktop,
    numColumnsMobile,
    mobileImagePosition = '',
  } = configData || {};

  if (configData?.type === 'blog') {
    return isMobile
      ? mobileImagePosition === 'left'
        ? 1
        : numColumnsMobile
      : numColumnsDesktop;
  }

  return isMobile ? numColumnsMobile : numColumnsDesktop;
}

// Returns number of columns
export function getContainerTracks({
  isLayoutCarousel,
  itemCount,
  isMobile,
  configData,
  expandSelectedReel = false,
  useStaticNumColumns = false,
}) {
  let numColumns = getNumsOfColumn(isMobile, configData);
  if (expandSelectedReel) {
    numColumns = 1;
  }
  if (!useStaticNumColumns && itemCount < numColumns) {
    numColumns = itemCount;
  }
  const numRowsForGrid = isMobile
    ? numColumns == 1
      ? MAX_NUM_ROWS_MOBILE_ONE_COL
      : MAX_NUM_ROWS_MOBILE_TWO_COL
    : MAX_NUM_ROWS_DESKTOP;
  const numRows = isLayoutCarousel ? 1 : numRowsForGrid; // for grid -> 'auto', for carousel -> 1;

  return { numColumns, numRows };
}

function getCardMinMaxWidth(type, isMobile, forType) {
  switch (type) {
    case 'icon':
    case 'image':
      return forType === 'max'
        ? MAX_CARD_WIDTH
        : isMobile
          ? MULTI_COLUMN_MIN_WIDTH_MOBILE
          : MULTI_COLUMN_MIN_WIDTH;
    case 'logoSlider':
      return forType === 'max' ? MAX_LOGO_SLIDER_CARD_WIDTH : MIN_LOGO_SLIDER_CARD_WIDTH;
    case 'featuredReels':
      return forType === 'max'
        ? !isMobile
          ? MAX_PRODUCT_REEL_CARD_WIDTH_DESKTOP
          : MAX_PRODUCT_REEL_CARD_WIDTH_MOBILE
        : !isMobile
          ? MIN_PRODUCT_REEL_CARD_WIDTH_DESKTOP
          : MIN_PRODUCT_REEL_CARD_WIDTH_MOBILE;
    case 'testimonials':
      return -1;
    default:
      return forType === 'max' ? MAX_CARD_WIDTH : MIN_CARD_WIDTH;
  }
}

function getMaxHeight(type) {
  switch (type) {
    case 'logoSlider':
      return MAX_LOGO_SLIDER_HEIGHT;
    case 'featuredReels':
      return MAX_PRODUCT_REEL_CARD_HEIGHT;
    default:
      return MAX_CARD_HEIGHT;
  }
}

// Width for each column (product item)
export function getOneCellGeometry({
  cardContainerWidthForEachCell,
  containerTracks,
  aspectRatio,
  widthAdditionals,
  type,
  isMobile,
  isLayoutTypeCard,
}) {
  let width = cardContainerWidthForEachCell / containerTracks.numColumns;
  // Reduce width slightly for each card so that an overflowed card is shown slightly to nudge the user to scroll
  width = width + widthAdditionals;

  const minWidth = getCardMinMaxWidth(type, isMobile, 'min');
  const maxWidth = getCardMinMaxWidth(type, isMobile, 'max');

  if (width > maxWidth && maxWidth > 0) width = maxWidth;
  if (width < minWidth && minWidth > 0) width = minWidth;

  // extract aspect ratio
  const [widthRatio, heightRatio] = aspectRatio.split(':').map((item) => +item);

  // calculate height
  let height = width * (heightRatio / widthRatio);
  if (height > getMaxHeight(type)) {
    height = getMaxHeight(type);
    width = !['logoSlider', 'testimonials'].includes(type)
      ? height * (widthRatio / heightRatio)
      : width;
  }

  // Meanwhile the width will be used to calculate the normal layout related things because the card will achieve the width
  // as calculated because in case of card layout border width will get added
  return {
    width,
    height: height,
    itemWidth: isLayoutTypeCard
      ? width - 2 // remove horizontal border width as border wil be applied to child of 1px
      : width,
  };
}

export function getDisplayLabelStyles(configData) {
  const textAlign = configData?.textAlign;
  const styles = {
    alignItems: '',
    width: '100%',
  };
  switch (textAlign) {
    case 'left':
      styles.alignItems = 'flex-start';
      break;
    case 'center':
      styles.alignItems = 'center';
      break;
    default:
      styles.alignItems = 'center';
      break;
  }

  return styles;
}

export function onCarouselScroll({
  e,
  setCarouselIndicatorConfigs,
  slideOffset = 0,
  widthOffset = 0,
}: IOnScrollCarousel) {
  const sectionViewportWidth = Math.floor(e.currentTarget.clientWidth);
  const sectionScrollWidth = Math.floor(e.currentTarget.scrollWidth);
  const currentScrollPosition = Math.floor(e.currentTarget.scrollLeft);
  const scrollPercent =
    (currentScrollPosition / (sectionScrollWidth - sectionViewportWidth)) * 100;
  // INITIAL_SCROLL_PERCENTAGE move advantage given
  const offsetPosition = scrollPercent * (INITIAL_SCROLL_PERCENTAGE / 100);
  // const currentFocusedSectionItem = Math.ceil(
  //   (currentScrollPosition + sectionViewportWidth) / sectionViewportWidth
  // );
  const currentFocusedSectionItem =
    Math.ceil(currentScrollPosition / (slideOffset - widthOffset)) + 1; // As this starts with 1 already

  setCarouselIndicatorConfigs((data) => {
    const newScrollPercentage =
      scrollPercent - offsetPosition + INITIAL_SCROLL_PERCENTAGE;

    return {
      ...data,
      direction: newScrollPercentage >= data.scrollPercentage ? 'to-right' : 'to-left',
      scrollPercentage: Math.ceil(newScrollPercentage),
      currentFocusedSectionItem:
        Math.ceil(newScrollPercentage) === INITIAL_SCROLL_PERCENTAGE ||
        Math.floor(newScrollPercentage) === INITIAL_SCROLL_PERCENTAGE
          ? 1
          : currentFocusedSectionItem,
      currentFocusedSectionExact: currentScrollPosition / (slideOffset - widthOffset) + 1,
    };
  });
}

export function getArrowMethods(
  itemsContainerRef: MutableRefObject<HTMLDivElement>,
  slideOffset
) {
  function onNextArrowClick() {
    itemsContainerRef.current?.scrollBy({
      top: 0,
      left: slideOffset,
      behavior: 'smooth',
    });
  }
  function onPrevArrowClick() {
    itemsContainerRef.current?.scrollBy({
      top: 0,
      left: -slideOffset,
      behavior: 'smooth',
    });
  }

  function carouselScrollTo(index) {
    const { clientWidth } = itemsContainerRef.current || {};
    itemsContainerRef.current?.scrollTo({
      left: clientWidth * index,
    });
  }

  return { onNextArrowClick, onPrevArrowClick, carouselScrollTo };
}

/* No padding is applied when carousel layout is chosen on mobile so scroll is from one screen edge
to another, so a margin is given on first & last element to give initial spacing before scroll starts **/
export const getCardFrameCustomStyle = ({
  isLayoutCarousel,
  isMobile,
  contentHorizontalPadding,
  index,
  arr,
}) => {
  let margin = '';
  if (isLayoutCarousel && isMobile) {
    if (!index) margin = `0px 0px 0px ${contentHorizontalPadding}px`;
    if (index === arr.length - 1) margin = `0px  ${contentHorizontalPadding}px 0px 0px`;
  }
  return { margin };
};

function getColumnGap({
  isMobile,
  type,
  isLayoutTypeCard,
  listPresentationConfig,
  expandSelectedReel,
}) {
  if (type === 'icon' || type === 'image') {
    return isMobile
      ? isLayoutTypeCard
        ? 8
        : 16
      : listPresentationConfig?.columnGap >= 0
        ? listPresentationConfig?.columnGap
        : 44;
  }

  if (type === 'logoSlider') return isMobile ? (!isLayoutTypeCard ? 16 : 10) : 20;

  if (type === 'blog') return isMobile ? 10 : 32;

  if (type === 'featuredReels' && expandSelectedReel) return isMobile ? 20 : 32;

  if (type === 'testimonials') return isMobile ? (!isLayoutTypeCard ? 16 : 12) : 20;

  return isMobile ? 10 : 20;
}

function getRowGap(
  isMobile,
  type,
  isLayoutTypeCard,
  isTextNameBelow,
  isCollectionNameVisible,
  numColumns
) {
  if (type === 'icon' || type === 'image')
    return !isMobile ? 44 : isLayoutTypeCard ? 8 : 16;

  if (type === 'logoSlider') return !isMobile ? 20 : !isLayoutTypeCard ? 20 : 10;

  if (type === 'blog') return isMobile ? (isLayoutTypeCard ? 10 : 24) : 32;

  if (type === 'testimonials') return isMobile ? (isLayoutTypeCard ? 20 : 44) : 20;

  return !isMobile
    ? isTextNameBelow && isCollectionNameVisible
      ? 40
      : 20
    : isTextNameBelow && isCollectionNameVisible
      ? 36
      : 10;
}

export const getGridGapsForAxes = ({
  isMobile,
  cardFrameConfig,
  listPresentationConfig,
  type,
  isLayoutTypeCard,
  expandSelectedReel,
  numColumns,
}) => {
  const { isTextNameBelow, isCollectionNameVisible } =
    getCardConfigurations(cardFrameConfig);

  return {
    columnGap: getColumnGap({
      isMobile,
      type,
      isLayoutTypeCard,
      listPresentationConfig,
      expandSelectedReel,
    }),
    rowGap: getRowGap(
      isMobile,
      type,
      isLayoutTypeCard,
      isTextNameBelow,
      isCollectionNameVisible,
      numColumns
    ),
  };
};

export const getReelCardConfigs = (config) => {
  const isCardLayout = config?.appearance === 'card';
  const isFullWidth = isCardLayout && config?.imageWidth === 'fullWidth';
  const style: React.CSSProperties = {
    background: isCardLayout ? config?.backgroundColor : 'transparent',
    borderColor: isCardLayout ? config?.borderColor : '#111C361F',
  };
  const className = isCardLayout ? 'tw-border-[1px] tw-border-solid' : '';

  return { style, className, isCardLayout, isFullWidth };
};

export function isLogoSliderAutoScroll({ cardFrameConfig, listPresentationConfig }) {
  return cardFrameConfig?.autoScroll && listPresentationConfig?.layoutType === 'carousel';
}

export function isLogoSliderFullWidth({ cardFrameConfig, listPresentationConfig }) {
  return (
    cardFrameConfig?.scrollFullWidth &&
    cardFrameConfig?.autoScroll &&
    listPresentationConfig?.layoutType === 'carousel'
  );
}

export const getRenderCardOverrides = ({ listPresentationConfig, sectionColors }) => {
  if (!listPresentationConfig?.colors?.defaultOverrides?.isEnabled) {
    return '';
  }
  switch (listPresentationConfig?.colors?.defaultOverrides?.colorSchemeName) {
    case 'custom':
      return sectionColors?.text;
    case 'white':
      return '#ffffff';
    default:
      return '';
  }
};

export function getItemCount({
  contentTypeConditions,
  listPresentationConfig,
  cardFrameConfig,
  iterableList,
}) {
  if (contentTypeConditions.multiColumn) {
    return listPresentationConfig?.numColumnsDesktop;
  }
  if (contentTypeConditions.logoSlider) {
    const visibleLogo = cardFrameConfig?.multiColumnData?.filter(
      (data) => data?.isVisible
    );
    return visibleLogo?.length;
  }
  return iterableList?.length;
}
