import React from 'react';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { ProductRating } from 'src/components/DesignSystem/Widgets2.0/ProductCard/muiStyles';
import { DEFAULT_IMAGE, IMAGE_TYPES, MEDIA_TYPES, ORDER_DATA_IDS } from '../../Constants';
import { ICardProps } from '../../Types';
import {
  getMappedProductCasing,
  getMappedproductsTextSize,
  getMappedProductTextAlign,
  getMappedProductTextThickness,
} from '../../../utils';
import { getCartWrapperConfigs } from 'src/components/DesignSystem/Widgets2.0/ProductCard/utils';
import classNames from 'classnames';

const Card: React.FC<ICardProps> = (props) => {
  const { ...infoWrapperStyles } = props.infoWrapperConfig || {};
  const cardWidth = props.oneGridCellGeometry.width;
  const {
    showAuthorInfo,
    authornameConfig: { ...authornameStyles } = {},
    authorProfileConfig: { ...authorProfileStyles } = {},
  } = props.authorInfoConfig || {};
  const isLayout5 = props.listPresentationConfig.layoutName === 'layout5';

  const { authorName, authorDesignation, review, authorMedia, headline } =
    props.testimonialData;

  const cardWrapperConfigs = getCartWrapperConfigs(props.listPresentationConfig);
  const showBorder = cardWrapperConfigs.isCardLayout;
  const colorConfig =
    props.listPresentationConfig?.colors?.colorSchemes?.[
      props.listPresentationConfig?.colors?.selectedColorSchemeIndex
    ];
  const showRatings = props.cardFrameConfig?.showRatings;
  const showImage = props.cardFrameConfig?.showImage;
  const authorImagePosition = (() => {
    const pos = props.cardFrameConfig?.authorImagePosition;
    return {
      withAuthorInfo: pos === 'withAuthorInfo',
      top: pos === 'top',
      bottom: pos === 'bottom',
      left: pos === 'left',
    };
  })();

  const reviewSize = getMappedproductsTextSize(
    props.cardFrameConfig?.[!props.isMobile ? 'textSizeDesktop' : 'textSizeMobile']
  );
  const reviewCasing = getMappedProductCasing(props.cardFrameConfig?.textCase);
  const reviewTextAlign = getMappedProductTextAlign(props.cardFrameConfig?.textAlign);
  const reviewTextThickness = getMappedProductTextThickness(
    props.cardFrameConfig?.textThickness
  );
  const headlineCasing = getMappedProductCasing(props.cardFrameConfig?.headlineCase);
  const headlineSize = getMappedproductsTextSize(
    props.cardFrameConfig?.[
      !props.isMobile ? 'headlineSizeDesktop' : 'headlineSizeMobile'
    ]
  );
  const headlineThickness = getMappedProductTextThickness(
    props.cardFrameConfig?.headlineThickness
  );

  function getImageClassesByType({ isVideo = false }) {
    let className = '';
    const style: React.CSSProperties = {};

    switch (props.cardFrameConfig.imageShape) {
      case IMAGE_TYPES.CIRCLE:
        className += ' tw-rounded-full ';
        break;
      case IMAGE_TYPES.RECT:
        className += ' tw-rounded-[8px] ';
        break;
    }

    const width =
      props.cardFrameConfig?.[props.isMobile ? 'imageSizeMobile' : 'imageSizeDesktop'];
    if (props.cardFrameConfig.authorImagePosition === 'left') {
      if (isLayout5) {
        style.maxWidth = '35%';
        style.width = isLayout5 ? `${cardWidth * (40 / 100)}px` : `${width}px`;
      } else {
        style.width = `${width}px`;
        if (isVideo && props.cardFrameConfig.imageShape === IMAGE_TYPES.CIRCLE) {
          style.height = `${width}px`;
        }
      }
    } else {
      style.width = `${width}px`;
      style.height = `${width}px`;
    }
    return { className, style };
  }

  function getWrapperStyles() {
    let className = '';
    const style: React.CSSProperties = {};
    if (showBorder) {
      className += ' tw-border tw-border-solid ';
      style.borderColor = props.listPresentationConfig.borderColor;
    }
    if (authorImagePosition.left) {
      style.gap = '3%';
    }
    return { style, className };
  }

  const wrapperStyling = getWrapperStyles();

  function renderAuthorImage() {
    if (!showImage) return null;
    const isVideo = authorMedia.type === MEDIA_TYPES.VIDEO;
    const styling = getImageClassesByType({ isVideo });
    return (
      <div
        className={classNames(
          `tw-flex tw-min-w-fit tw-overflow-hidden`,
          styling.className
        )}
        style={styling.style}
      >
        {authorMedia.type !== MEDIA_TYPES.VIDEO ? (
          <img
            style={{ ...styling.style }}
            className="removeGlobalOverride tw-max-w-full tw-object-cover"
            src={authorMedia.link || DEFAULT_IMAGE}
          />
        ) : (
          <video
            src={authorMedia.link || DEFAULT_IMAGE}
            autoPlay
            muted
            style={{ ...styling.style }}
            className="tw-max-w-full tw-object-cover"
          />
        )}
      </div>
    );
  }

  function renderRating() {
    if (!showRatings) return null;
    return (
      <div
        className={classNames(
          'tw-flex',
          props.cardFrameConfig?.textAlign === 'center' && 'tw-justify-center'
        )}
      >
        <ProductRating
          value={4}
          sx={{
            color: props.cardFrameConfig?.ratingColor || '#F9CC43',
          }}
          fontSize="20px"
          precision={0.1}
          readOnly
          icon={<StarRoundedIcon fontSize="inherit" />}
          emptyIcon={<StarRoundedIcon fontSize="inherit" />}
        />
      </div>
    );
  }

  function renderReview() {
    return (
      <div className="tw-flex tw-flex-grow tw-flex-col tw-gap-[12px]">
        {!!headline?.trim() && (
          <p
            className="tw-m-0"
            style={{
              ...headlineThickness,
              ...reviewTextAlign,
              ...headlineCasing,
              ...headlineSize,
              color: props.cardFrameConfig.headlineColor,
            }}
          >
            {headline}
          </p>
        )}
        <p
          className="tw-m-0"
          style={{
            ...reviewTextThickness,
            ...reviewTextAlign,
            ...reviewCasing,
            ...reviewSize,
            color: props.cardFrameConfig.reviewColor,
          }}
        >
          "{review}"
        </p>
      </div>
    );
  }

  function renderAuthorInfo() {
    return (
      showAuthorInfo && (
        <div
          className={classNames(
            'tw-flex tw-w-full tw-items-center tw-gap-[12px]',
            props.cardFrameConfig?.textAlign === 'center' && 'tw-justify-center',
            authorImagePosition.bottom && 'tw-flex-col'
          )}
        >
          {(authorImagePosition.withAuthorInfo || authorImagePosition.bottom) &&
            renderAuthorImage()}
          <div
            className={classNames(
              'tw-flex tw-flex-col tw-text-[#050505]',
              props.cardFrameConfig?.textAlign === 'center' &&
                !showImage &&
                'tw-text-center'
            )}
            style={{
              color: colorConfig.text,
              ...(!authorImagePosition.withAuthorInfo ? reviewTextAlign : {}),
            }}
          >
            <h5
              className="tw-m-0"
              style={{
                ...authornameStyles,
                color: props.cardFrameConfig.authorNameColor,
              }}
            >
              {authorName || 'Anonymous'}
            </h5>
            {!!authorDesignation && (
              <p
                className="tw-m-0 tw-text-[12px]"
                style={{
                  ...authorProfileStyles,
                  color: props.cardFrameConfig.designationColor,
                  fontSize: '12px',
                }}
              >
                {authorDesignation}
              </p>
            )}
          </div>
        </div>
      )
    );
  }

  function renderByDataId(id) {
    switch (id) {
      case ORDER_DATA_IDS.RATING:
        return renderRating();
      case ORDER_DATA_IDS.REVIEW:
        return renderReview();
      case ORDER_DATA_IDS.AUTHOR_INFO:
        return renderAuthorInfo();
      default:
        return null;
    }
  }

  function getDataByOrder() {
    return props.dataOrder.map((data) => renderByDataId(data?.id));
  }

  if (!review?.trim()) return null;
  return (
    <div
      className={classNames(
        `tw-flex tw-flex-shrink-0 tw-items-start tw-justify-between tw-rounded-[8px]`,
        cardWrapperConfigs.isCardLayout &&
          `tw-py-[24px] ${props.isMobile ? 'tw-px-[14px]' : 'tw-px-[20px]'}`,
        wrapperStyling.className
      )}
      style={{
        width: `${cardWidth}px`,
        ...wrapperStyling.style,
        backgroundColor: cardWrapperConfigs.isCardLayout
          ? props.listPresentationConfig.backgroundColor
          : '',
      }}
    >
      {authorImagePosition.left && renderAuthorImage()}
      <div className="tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-between">
        {authorImagePosition.top && renderAuthorImage()}
        <div
          className={classNames(
            'tw-items tw-flex tw-flex-1 tw-flex-col tw-items-stretch tw-justify-between',
            authorImagePosition.top && 'tw-mt-[16px]'
          )}
          style={infoWrapperStyles}
        >
          {getDataByOrder()}
        </div>
      </div>
    </div>
  );
};

export default Card;
