import store from 'src/redux/store';
import { PATH } from './routes';
import { NEW_STORE_WEBSITE_STATUS } from 'src/constants/subscription';

export const canDisablePlaceOrder = () => {
  const storeInfo = store.getState()?.storeReducer?.store;

  if (process.env.NEXT_PUBLIC_CUSTOM_ENV !== 'PROD') {
    return false;
  }

  return (
    window.location.pathname?.includes(PATH.BUILDER_PREVIEW) ||
    // for new stores
    storeInfo?.theme?.new_subscription_web_status ===
      NEW_STORE_WEBSITE_STATUS.ACTIVE_NOT_LIVE ||
    // for old stores
    storeInfo?.domain_info?.domain_type === 0
  );
};
