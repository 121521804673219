import { ICardDefaultConfigProps, ImageType } from '../Types';

export const TESTIMONIAL_LAYOUT_TYPES = {
  ONE: 'layout1',
  TWO: 'layout2',
  THREE: 'layout3',
  FOUR: 'layout4',
  FIVE: 'layout5',
  SIX: 'layout6',
};

export const IMAGE_TYPES = {
  CIRCLE: 'circle' as ImageType,
  SQUARE: 'square' as ImageType,
  RECT: 'rectangle' as ImageType,
};

export const ORDER_DATA_IDS = {
  RATING: 'rating',
  REVIEW: 'review',
  AUTHOR_INFO: 'authorInfo',
};

export const MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
};

// will be removed later
export const CARD_DEFAULT_CONFIGS: { [layout: string]: ICardDefaultConfigProps } = {
  layout1: {
    cardWrapperConfig: {
      showBorder: false,
      padding: '35px 28px',
    },
    imageConfig: {
      showImage: true,
      showImageWithAuthorInfo: true,
      imageType: IMAGE_TYPES.CIRCLE,
      width: 40,
      height: 40,
    },
    infoWrapperConfig: {
      gap: 24,
    },
    reviewConfig: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 400,
      color: '#4D4D4D',
      textAlign: 'center',
    },
    authorInfoConfig: {
      showAuthorInfo: true,
      gap: 0,
      authornameConfig: {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 600,
      },
      authorProfileConfig: {
        fontWeight: 400,
      },
    },
  },
  layout2: {
    cardWrapperConfig: {
      showBorder: false,
      padding: '35px 28px',
    },
    imageConfig: {
      showImage: true,
      showImageWithAuthorInfo: true,
      imageType: IMAGE_TYPES.CIRCLE,
      width: 40,
      height: 40,
    },
    infoWrapperConfig: {
      gap: 24,
    },
    reviewConfig: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 400,
      color: '#4D4D4D',
      textAlign: 'center',
    },
    authorInfoConfig: {
      showAuthorInfo: true,
      gap: 0,
      authornameConfig: {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 600,
      },
      authorProfileConfig: {
        fontWeight: 400,
      },
    },
  },
  layout3: {
    cardWrapperConfig: {
      showBorder: false,
      flexDirection: 'column',
      gap: 18,
    },
    imageConfig: {
      showImage: true,
      showImageWithAuthorInfo: false,
      imageType: IMAGE_TYPES.CIRCLE,
      width: 76,
      height: 76,
    },
    infoWrapperConfig: {
      gap: 10.5,
    },
    reviewConfig: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 400,
      color: '#050505',
      textAlign: 'center',
    },
    authorInfoConfig: {
      showAuthorInfo: true,
      gap: 0,
      alignItems: 'center',
      authornameConfig: {
        fontSize: 10,
        lineHeight: '14px',
        fontWeight: 800,
      },
      authorProfileConfig: {
        fontWeight: 400,
      },
    },
  },
  layout4: {
    showAuthorImageList: true,
    cardWrapperConfig: {
      showBorder: false,
      paddingBottom: 100,
    },
    imageConfig: {
      showImage: false,
      showImageWithAuthorInfo: false,
      imageType: IMAGE_TYPES.CIRCLE,
      width: 40,
      height: 40,
    },
    infoWrapperConfig: {
      gap: 40,
    },
    reviewConfig: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 400,
      color: '#4D4D4D',
      textAlign: 'center',
    },
    authorInfoConfig: {
      showAuthorInfo: false,
    },
  },
  layout5: {
    cardWrapperConfig: {
      showBorder: false,
      gap: 34,
      padding: 0,
    },
    imageConfig: {
      showImage: true,
      showImageWithAuthorInfo: false,
      imageType: IMAGE_TYPES.RECT,
      width: 236,
      height: 316,
      flex: 'none',
    },
    infoWrapperConfig: {
      gap: 24,
      alignItems: 'start',
    },
    reviewConfig: {
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 400,
      color: '#111C36',
    },
    authorInfoConfig: {
      showAuthorInfo: true,
      gap: 9,
      authornameConfig: {
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 700,
      },
      authorProfileConfig: {
        fontWeight: 400,
      },
    },
  },
  layout6: {
    cardWrapperConfig: {
      showBorder: true,
      borderColor: '#111C3633',
      gap: 32,
      flexDirection: 'column',
      padding: '32px 48px',
    },
    imageConfig: {
      showImage: true,
      showImageWithAuthorInfo: false,
      imageType: IMAGE_TYPES.CIRCLE,
      width: 180,
      height: 180,
    },
    infoWrapperConfig: {
      gap: 20,
    },
    reviewConfig: {
      fontSize: 22,
      lineHeight: '32px',
      fontWeight: 400,
      color: '#4D4D4D',
      textAlign: 'center',
    },
    authorInfoConfig: {
      showAuthorInfo: true,
      gap: 8,
      alignItems: 'center',
      authornameConfig: {
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 700,
      },
      authorProfileConfig: {
        fontWeight: 400,
      },
    },
  },
};

// chnage later
export const DEFAULT_IMAGE =
  'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/default-avatar.png';
