import { FunctionComponent, PropsWithChildren, useEffect, useLayoutEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import styles from './styles.module.scss';
import { useWidgetDndContextData } from '@/context/WidgetDndContext';
import { Settings } from 'react-slick';
import { IS_SERVER } from 'src/utils/checkRenderEnv';
import { BUTTON_VARIANT } from 'src/components/WidgetMaker/utils/buttonConstant';
import { ADMIN_WIDGETS } from 'src/constants/widgets';
export interface WidgetOptions {
  name: string;
  engine: string;
  height: string;
  aspectRatio: string;
  heightMobile: string;
  aspectRatioMobile: string;
  autoplaySpeed: number;
  hideLogo: boolean;
  hideStoreName: boolean;
  showDescription: boolean;
  links: { href: string }[];
  images: { src: string; is_visible: boolean }[];
  imagesMobile: { src: string; is_visible: boolean }[];
  marginTop: number;
  marginBottom: number;
}

export interface WidgetProps {
  store: any;
  cart: any;
  catalog: any;
  user: any;
  promo: any;
  widgetOptions: WidgetOptions;
  update: number;
}

export const DEFAULT_WIDGET_OPTIONS: Partial<WidgetOptions> = {
  aspectRatio: '',
  aspectRatioMobile: '',
  autoplaySpeed: 5000,
  hideLogo: false,
  hideStoreName: false,
  showDescription: false,
  links: [],
  marginTop: 0,
  marginBottom: 0,
};

export const sliderSettings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
  autoplaySpeed: 3000,
  dotsClass: 'herobanner_slider--icons',
};
interface IRDecodeAspectRatio {
  height: number;
  width: number;
}
const decodeAspectRatio = (aspectRatio: string): IRDecodeAspectRatio => {
  const splittedAR = aspectRatio
    ? // if the aspect ratio was not splitted
      aspectRatio?.split(' / ')[0] !== aspectRatio
      ? aspectRatio?.split(' / ')
      : aspectRatio?.split('/')
    : null;

  return { width: +splittedAR?.[0], height: +splittedAR?.[1] };
};

export const getInnerWidth = () => {
  let innerWidth = 0;
  if (IS_SERVER) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const userAgent = useSSRSelector((state) => state.commonReducer.userAgent);
    // const isInitialRequestByMobile = isMobileByUA(userAgent);
    // innerWidth = isInitialRequestByMobile ? 719 : 721;
    innerWidth = 0;
  } else {
    innerWidth = window?.innerWidth;
  }
  return innerWidth;
};

export const filterImages = (bannerImages, isDesktop, widgetOptions: WidgetOptions) => {
  // const innerWidth = getInnerWidth();
  let filteredImages = [];
  if (IS_SERVER) {
    return filteredImages;
  }
  if (isDesktop) {
    if (widgetOptions?.images?.length) {
      // filteredImages = widgetOptions.images.map((image) => ({ image_url: image.src }));
      filteredImages = widgetOptions.images?.filter((img) => img.is_visible !== false);
    } else {
      filteredImages = bannerImages
        .filter((img) => img.is_desktop && img.is_visible !== false)
        .sort((a, b) => a.order - b.order);
    }
  } else {
    if (widgetOptions.imagesMobile?.length) {
      // filteredImages = widgetOptions.imagesMobile.map((image) => ({
      //   image_url: image.src,
      // }));
      filteredImages = widgetOptions.imagesMobile?.filter(
        (img) => img.is_visible !== false
      );
    } else {
      filteredImages = bannerImages
        .filter((img) => !img.is_desktop && img.is_visible !== false)
        .sort((a, b) => a.order - b.order);
    }
  }

  return filteredImages;
};

export const getHeight = (
  widgetOptions: WidgetOptions,
  availableWidth,
  isNewCarousel
) => {
  const innerWidth = getInnerWidth();
  availableWidth = availableWidth || innerWidth;

  let height = 'auto';
  if (availableWidth > 720) {
    const { width: bannerWidth, height: bannerHeight } = decodeAspectRatio(
      widgetOptions?.aspectRatio
    );
    const defaultHeight = widgetOptions.height?.includes?.('px')
      ? widgetOptions.height?.split('px')[0]
      : widgetOptions.height;
    height =
      isNewCarousel && bannerHeight && bannerWidth
        ? `${(availableWidth / bannerWidth) * bannerHeight}px`
        : `${defaultHeight || bannerHeight}px`;
  } else {
    const { width: bannerWidth, height: bannerHeight } = decodeAspectRatio(
      widgetOptions?.aspectRatioMobile
    );
    const defaultHeight = widgetOptions.heightMobile?.includes?.('px')
      ? widgetOptions.heightMobile?.split('px')[0]
      : widgetOptions.heightMobile;
    height =
      isNewCarousel && bannerHeight && bannerWidth
        ? `${(availableWidth * bannerHeight) / bannerWidth}px`
        : `${defaultHeight || bannerHeight || widgetOptions.height}px`;
  }
  return height;
};

export const getAspectRatio = (widgetOptions: WidgetOptions, availableWidth) => {
  const innerWidth = getInnerWidth();
  availableWidth = availableWidth || innerWidth;
  let aspectRatio = '';
  if (availableWidth > 720) {
    // aspectRatio = widgetOptions.height ? 'unset' : widgetOptions.aspectRatio;
    aspectRatio = widgetOptions?.aspectRatio;
  } else {
    // aspectRatio = widgetOptions.heightMobile
    //   ? 'unset'
    //   : widgetOptions.aspectRatioMobile || widgetOptions.aspectRatio || aspectRatio;
    aspectRatio = widgetOptions?.aspectRatioMobile;
  }
  return aspectRatio;
};

export const StoreName = (props: WidgetProps) => {
  const widgetOptions = props.widgetOptions;
  if (
    widgetOptions.hideLogo &&
    widgetOptions.hideStoreName &&
    !widgetOptions.showDescription
  ) {
    return null;
  }
  return (
    <div className="storeName-container">
      {!props.widgetOptions.hideLogo && (
        <div className="storeName-image-container">
          <img alt="Store logo" src={props?.store?.logo_image} />
        </div>
      )}
      {!props.widgetOptions.hideStoreName && (
        <h2 className="storeName-name">{props.store?.store_name}</h2>
      )}
      {props.widgetOptions.showDescription && (
        <p className="storeName-description">{props.store?.description}</p>
      )}
    </div>
  );
};

export const WithLink: FunctionComponent<
  PropsWithChildren<WidgetProps & { index: number; redirectLink?: string }>
> = (props) => {
  if (props.redirectLink) {
    return <a href={props.redirectLink}>{props.children}</a>;
  } else {
    return props.children;
  }
};

export const getButtonVariantsData = (buttonData, useDefaultStyles = true) => ({
  solid: {
    variant: BUTTON_VARIANT.SECONDARY,
    customClasses: ` tw-w-[200px] ${!buttonData?.btnStyleConfig?.textColor ? '!tw-text-[#111C36]' : ''}
       ${!buttonData?.btnStyleConfig?.backgroundColor ? '!tw-bg-[#ffffff]' : ''} ${useDefaultStyles ? '!tw-py-[18px]' : ''} `,
  },
  outline: {
    variant: BUTTON_VARIANT.SECONDARY,
    customClasses: ` tw-w-[200px] ${!buttonData?.btnStyleConfig?.textColor ? '!tw-text-[#ffffff]' : ''} ${useDefaultStyles ? '!tw-py-[18px]' : ''} 
    ${!buttonData?.btnStyleConfig?.backgroundColor ? '!tw-bg-[#0000001a] ' : ''} ${!buttonData?.btnStyleConfig?.backgroundColor ? '!tw-border-[#ffffff]' : ''} `,
    animationColor: ` ${
      buttonData?.buttonAnimationConfig?.classKey &&
      !buttonData?.buttonAnimationConfig?.classKey.toLowerCase()?.includes('move')
        ? ' hover:!tw-text-newBlack !tw-transition-[color] '
        : ''
    } `,
  },
  textual: {
    variant: BUTTON_VARIANT.TERTIARY,
    customClasses: twMerge(styles.tertiaryBtnCustomStyle),
    animationColor:
      ' !tw-py-0 tw-w-auto  !tw-text-[#ffffff] ' + buttonData?.btnStyleConfig?.textColor
        ? ''
        : buttonData?.buttonAnimationConfig?.classKey
          ? ' [&>p]:before:!tw-bg-[#ffffff] '
          : ' ',
  },
});

export const useUpgradeEditorBannerSelection = ({
  activeSlide2,
  setActiveSlide2,
  id,
  sliderRef,
  widgetOptions,
  isBuilderChevronEnabled,
}) => {
  const {
    widgetContextState: { widgets, widgetSettingsSectionState, activeWidgetId },
    widgetContextActions: { setWidgetSettingsSectionState },
  } = useWidgetDndContextData();

  const selectedBannerEditor = widgetSettingsSectionState.singleBannerSettings;
  const activeWidget = widgets[id];
  const activeWidgetProps = activeWidget?.props;

  const allSliderSettings: Settings = {
    afterChange: (current) => setActiveSlide2(current),
    onSwipe(swipeDirection) {
      let newSelectedIndex = null;
      if (swipeDirection === 'left') {
        newSelectedIndex =
          activeSlide2 === 0 ? activeWidgetProps?.images?.length - 1 : activeSlide2 - 1;
        upgradeEditorBannerSelection('prev', newSelectedIndex);
      } else {
        newSelectedIndex =
          activeSlide2 === activeWidgetProps?.images?.length - 1 ? 0 : activeSlide2 + 1;
        upgradeEditorBannerSelection('next', newSelectedIndex);
      }
    },
    ...sliderSettings,
    autoplaySpeed: widgetOptions.autoplaySpeed,
    autoplay: selectedBannerEditor.show ? false : widgetOptions.autoPlay ?? true,
    dots: !isBuilderChevronEnabled,
  };

  const upgradeEditorBannerSelection = (type, activeSlide = activeSlide2) => {
    const bannerLength = widgets[activeWidgetId]?.props?.images?.length - 1;
    if (!selectedBannerEditor.show) {
      return;
    }
    let newSelectedIndex = null;
    if (type === 'next') {
      newSelectedIndex =
        activeSlide === activeWidgetProps?.images?.length - 1
          ? 0
          : bannerLength === activeSlide
            ? 0
            : activeSlide + 1;
    } else {
      newSelectedIndex =
        activeSlide === 0
          ? bannerLength
          : activeSlide === 0
            ? bannerLength
            : activeSlide - 1;
    }

    setWidgetSettingsSectionState({
      singleBannerSettings: {
        show: true,
        data: {
          bannerData: activeWidgetProps?.images?.[newSelectedIndex],
          bannerIndex: newSelectedIndex,
        },
        heading: `Banner ${newSelectedIndex + 1}`,
      },
    });
    setTimeout(() => {
      setActiveSlide2(newSelectedIndex);
    }, 1200);
  };

  const handlePrevClick = () => {
    sliderRef.current?.slickPrev?.();
    upgradeEditorBannerSelection('prev');
  };

  const handleNextClick = () => {
    sliderRef.current?.slickNext?.();
    upgradeEditorBannerSelection('next');
  };

  useEffect(() => {
    if (selectedBannerEditor.show) {
      sliderRef.current?.slickGoTo(selectedBannerEditor.data.bannerIndex);
      setActiveSlide2(selectedBannerEditor.data.bannerIndex);
      sliderRef.current?.slickPause();
    } else {
      if (widgetOptions.autoPlay) sliderRef.current?.slickPlay();
      else sliderRef.current?.slickPause();
    }
  }, [selectedBannerEditor.show]);

  /**
   * Bwloq code might be required for future reference, will be deleted when the Hero banner has gone through QA
   * 
  // const [allSliderSettings, setAllSliderSetting] = useState<Settings>({
  //   ...sliderSettings,
  //   afterChange: (current) => setActiveSlide2(current),
  //   beforeChange: (current, next) => {
  //     if (current < next) {
  //       // sliderRef.current.slickNext();
  //       upgradeEditorBannerSelection('next', next);
  //       setActiveSlide2(next);
  //     }
  //     if (current > next) {
  //       // sliderRef.current.slickPrev();
  //       upgradeEditorBannerSelection('prev', current);
  //       setActiveSlide2(current);
  //     }
  //   },
  //   autoplaySpeed: widgetOptions.autoplaySpeed,
  //   autoplay: widgetOptions.autoPlay ?? true,
  // });

  // useEffect(() => {
  //   setAllSliderSetting((v) => ({
  //     ...v,
  //     autoplaySpeed: widgetOptions.autoplaySpeed,
  //     // autoplay: widgetOptions.autoPlay ?? true,
  //   }));
  //   const timerId = setTimeout(() => {
  //     setAllSliderSetting((v) => ({
  //       ...v,
  //       autoplay: selectedBannerEditor.show ? false : widgetOptions.autoPlay ?? true,
  //     }));
  //   }, 1000);
  //   return () => {
  //     clearTimeout(timerId);
  //   };
  // }, [widgetOptions.autoplaySpeed, widgetOptions.autoPlay, selectedBannerEditor]);

   */

  return {
    allSliderSettings,
    handlePrevClick,
    handleNextClick,
    setWidgetSettingsSectionState,
    upgradeEditorBannerSelection,
  };
};

export const useWindowResize = ({
  dependencies = [],
  executor,
  widgetOptions,
  props,
}) => {
  const {
    widgetContextState: { showPreview, previewDevice },
  } = useWidgetDndContextData();

  useLayoutEffect(() => {
    window.addEventListener('resize', executor);
    return () => {
      window.removeEventListener('resize', executor);
    };
  }, dependencies);

  useLayoutEffect(() => {
    executor();
  }, [
    props.update,
    widgetOptions.aspectRatio,
    widgetOptions.aspectRatioMobile,
    showPreview,
    previewDevice,
  ]);
};

export function getBannerImages({ props, activeWidgetProps, isDesktop }) {
  const bodyComponent = props.store?.theme?.components?.Body || [];
  const bannerImages =
    bodyComponent.find(
      (component) => component.sub_type === ADMIN_WIDGETS.HERO_BANNER.type
    )?.images ||
    bodyComponent.find((component) => component.sub_type === 'HeroBanner')?.images ||
    [];
  const filteredImages = filterImages(
    bannerImages,
    isDesktop,
    activeWidgetProps || props.widgetOptions
  );
  return filteredImages;
}

export const useGetAlignments = ({ isDesktop, widgetOptions }) => {
  const getContentPosition = () => {
    if (isDesktop) {
      return widgetOptions?.contentPositionDesktop;
    }
    return widgetOptions?.contentPositionMobile;
  };

  const getButtonAlignment = (classType = 'position') => {
    const position = getContentPosition();
    if (position === 'left')
      return classType === 'flex'
        ? isDesktop
          ? 'tw-justify-start'
          : 'tw-items-start'
        : 'tw-right-[0px]';
    if (position === 'right')
      return classType === 'flex'
        ? isDesktop
          ? 'tw-justify-end'
          : 'tw-items-end'
        : 'tw-left-[0px]';
    return classType === 'flex'
      ? isDesktop
        ? 'tw-justify-center'
        : 'tw-items-center'
      : 'tw-top-[50%] tw--translate-y-1/2';
  };

  const getContentAlignment = (classType = 'position') => {
    const position = getContentPosition();
    if (position === 'left')
      return classType === 'flex' ? 'tw-justify-start' : 'tw-left-[0px]';
    if (position === 'right')
      return classType === 'flex' ? 'tw-justify-end' : 'tw-right-[0px]';
    return classType === 'flex'
      ? 'tw-justify-center'
      : 'tw-left-[50%] tw-translate-x-1/2';
  };

  return { getContentAlignment, getButtonAlignment, getContentPosition };
};
